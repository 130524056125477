import {
    NgModule,
    isDevMode,
    provideExperimentalZonelessChangeDetection,
    inject,
    provideAppInitializer,
} from "@angular/core";
import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatSnackBarModule,
} from "@angular/material/snack-bar";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { ServiceWorkerModule } from "@angular/service-worker";
import {
    provideHttpClient,
    withInterceptors,
    withFetch,
} from "@angular/common/http";
import { authHeaderInterceptor } from "./core/auth/infrastructure/interceptors/auth-header.interceptor";
import { userInterceptor } from "./login/infrastructure/interceptors/user.interceptor";
import { systemSettingsInterceptor } from "./core/system-settings/infrastructure/interceptors/system-settings.interceptor";
import { templateInterceptor } from "./core/infrastructure/interceptors/template.interceptor";
import { chunkInterceptor } from "./core/loadProcess/infrastructure/interceptors/chunk.interceptor";
import { largeObjectInterceptor } from "./core/loadProcess/infrastructure/interceptors/large-object.interceptor";
import { mapOverlayInterceptor } from "./core/loadProcess/infrastructure/interceptors/map-overlay.interceptor";
import { serverTimeoutInterceptor } from "./core/server-timeout/infrastructure/interceptors/server-timeout.interceptor";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { notificationInterceptor } from "./inbox/infrastructure/interceptors/notification.interceptor";
import { recordInstanceInterceptor } from "./core/loadProcess/infrastructure/interceptors/record-instance.interceptor";
import { DefaultSnackbarSettings } from "./core/system-settings/default-settings/default-snackbar.settings";
import { subscriptionSearchResultsInterceptor } from "./record-list-view/infrastructure/interceptors/subscription-search-results.interceptor";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { AppRouteReuseStrategy } from "./app-routing.strategy";
import { GoogleMapsService } from "./shared/infrastructure/services/google-maps.service";
import { initializeGoogleMaps } from "./shared/infrastructure/factories/google-maps.service.factory";
import { LoadingSpinnerModule } from "./shared/presentation/loading-spinner/loading-spinner.module";
import { inProgressInterceptor } from "./core/in-progress/interceptors/in-progress.interceptor";
import { syncSinceInterceptor } from "./core/loadProcess/infrastructure/interceptors/sync-since.interceptor";
import { getTopLevelRecordsInterceptor } from "./core/loadProcess/infrastructure/interceptors/get-top-level-records.interceptor";

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        AppRoutingModule,
        RouterModule,
        MatSnackBarModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
        LoadingSpinnerModule,
    ],
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideAnimationsAsync(),
        provideHttpClient(
            withFetch(),
            withInterceptors([
                systemSettingsInterceptor,
                authHeaderInterceptor,
                serverTimeoutInterceptor,
                // tokenInterceptor,
                templateInterceptor,
                mapOverlayInterceptor,
                userInterceptor,
                chunkInterceptor,
                largeObjectInterceptor,
                notificationInterceptor,
                subscriptionSearchResultsInterceptor,
                recordInstanceInterceptor,
                inProgressInterceptor,
                syncSinceInterceptor,
                getTopLevelRecordsInterceptor,
            ])
        ),
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: DefaultSnackbarSettings.timeout,
            },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline", subscriptSizing: "dynamic" },
        },
        {
            provide: RouteReuseStrategy,
            useClass: AppRouteReuseStrategy,
        },
        GoogleMapsService,
        provideAppInitializer(() =>
            initializeGoogleMaps(inject(GoogleMapsService))()
        ),
    ],
})
export class AppModule {}
