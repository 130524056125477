import { inject, Injectable } from "@angular/core";
import { CalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/calculated-field-template.dto";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import {
    dateSet,
    datetimeSet,
    moneySet,
    numberSet,
} from "../../domain/enums/calculated-types";
import { Operations } from "../../domain/enums/operations.enum";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { Graph } from "../data/model/templateGraph/graph";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { LOGGER } from "../../logging/providers/logger.provider";
import { AddConfigurationErrorUsecase } from "../../../inbox/core/usecases/configuration-errors/add-configuration-error.usecase";
import { ConfigurationErrorDetails } from "../../../inbox/core/domain/configuration-error-details.model";
import { ConfigurationErrorType } from "../../../inbox/core/domain/configuration-error-type.enum";

@Injectable({
    providedIn: "root",
})
export class CalculatedFieldTransformService {
    private _addConfigurationErrorUsecase = inject(
        AddConfigurationErrorUsecase
    );
    logger = inject(LOGGER);
    transformCalculatedFieldType(
        calculatedField: CalculatedFieldTemplateDTO,
        field1: FieldTemplateDTO,
        field2: FieldTemplateDTO
    ): void {
        switch (true) {
            case dateSet.has(field1.type) &&
                dateSet.has(field2.type) &&
                calculatedField.operation === Operations.Subtract:
                calculatedField.type = FieldTypes.CalculatedAge;
                break;
            case datetimeSet.has(field1.type) &&
                datetimeSet.has(field2.type) &&
                calculatedField.operation === Operations.Subtract:
                calculatedField.type = FieldTypes.CalculatedTime;
                break;
            //Handle invalid calculation of adding dates. Comes in as date value from server
            case (datetimeSet.has(field1.type) || dateSet.has(field1.type)) &&
                (datetimeSet.has(field2.type) || dateSet.has(field2.type)) &&
                calculatedField.operation === Operations.Add:
                calculatedField.allowUpdates = false;
                calculatedField.type = FieldTypes.CalculatedDate;
                this.logger.warn(
                    `Invalid calculation for fieldId ${calculatedField.fieldID}: ${field1.type} ${calculatedField.operation} ${field2.type}`
                );
                break;
            //Handle invalid calculation of subtracting date and datetme.
            case (datetimeSet.has(field1.type) || dateSet.has(field1.type)) &&
                (datetimeSet.has(field2.type) || dateSet.has(field2.type)) &&
                calculatedField.operation === Operations.Subtract:
                calculatedField.type = FieldTypes.CalculatedTime;
                this.logger.warn(
                    `Invalid calculation for fieldId ${calculatedField.fieldID}: ${field1.type} ${calculatedField.operation} ${field2.type}`
                );
                break;
            case dateSet.has(field1.type) && numberSet.has(field2.type):
            case numberSet.has(field1.type) && dateSet.has(field2.type):
                calculatedField.type = FieldTypes.CalculatedDate;
                break;
            case datetimeSet.has(field1.type) && numberSet.has(field2.type):
            case numberSet.has(field1.type) && datetimeSet.has(field2.type):
                calculatedField.type = FieldTypes.CalculatedDateTime;
                break;
            case moneySet.has(field1.type) && moneySet.has(field2.type):
                calculatedField.type = FieldTypes.CalculatedMoney;
                break;
            case numberSet.has(field1.type) && numberSet.has(field2.type):
                calculatedField.type = FieldTypes.CalculatedNumber;
                break;
            case calculatedField.operation === Operations.ElapsedTime:
                calculatedField.type = FieldTypes.CalculatedElapsedTime;
                break;
            default:
                //Throw error that configuration is not supported
                throw Error(
                    `Calculated field configuration not supported: ${field1.type} ${calculatedField.operation} ${field2.type}`
                );
        }
    }
    transformCalculatedFieldTypes(graph: Graph<DatalistTemplateDTO>) {
        const fieldsToCheckAgain = [];

        for (const datalistNode of graph.nodes.values()) {
            for (const calculatedFieldId of datalistNode.calculatedFields) {
                const calculatedField = datalistNode.fieldMapByFieldId.get(
                    calculatedFieldId
                ) as CalculatedFieldTemplateDTO;

                if (!calculatedField) {
                    throw new Error("Calculated field not found");
                }

                calculatedField.readOnly = true;

                const field1 = datalistNode.fieldMapByFieldId.get(
                    calculatedField.field1Id
                );
                if (!field1) {
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.CalculatedFieldDependency,
                            `Calculated Field source Field1 not found for calculated field ${calculatedField.systemName}`,
                            datalistNode.data.datalistID
                        )
                    );
                    return;
                }

                if (calculatedField.operation === Operations.ElapsedTime) {
                    calculatedField.type = FieldTypes.CalculatedElapsedTime;
                    continue;
                }

                if (!calculatedField.field2Id) {
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.CalculatedFieldDependency,
                            `Calculated Field source Field2ID not found for calculated field ${calculatedField.systemName}`,
                            datalistNode.data.datalistID
                        )
                    );
                    return;
                }
                const field2 = datalistNode.fieldMapByFieldId.get(
                    calculatedField.field2Id
                );
                if (!field2) {
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.CalculatedFieldDependency,
                            `Calculated Field source Field2 not found for calculated field ${calculatedField.systemName}`,
                            datalistNode.data.datalistID
                        )
                    );
                    return;
                }

                //If one of the field is a calculated field, then we need to check again later once all calculated types are resolved
                if (
                    field1.type === FieldTypes.CalculatedField ||
                    field2.type === FieldTypes.CalculatedField
                ) {
                    fieldsToCheckAgain.push({
                        calculatedField,
                        field1,
                        field2,
                    });
                    continue;
                }

                this.transformCalculatedFieldType(
                    calculatedField,
                    field1,
                    field2
                );
            }
        }

        //TODO: Check for circular dependencies https://github.com/RedMane/mCase.Phoenix/issues/654
        while (fieldsToCheckAgain.length > 0) {
            const calculation: any = fieldsToCheckAgain.shift()!;
            const field1 = calculation.field1;
            const field2 = calculation.field2;
            const calculatedField = calculation.calculatedField;

            if (
                field1.type === FieldTypes.CalculatedField ||
                field2.type === FieldTypes.CalculatedField
            ) {
                fieldsToCheckAgain.push(calculation);
                continue;
            }

            this.transformCalculatedFieldType(calculatedField, field1, field2);
        }
    }
}
