import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { JsonRecordInstanceMapper } from "../../../../record/data/transformationMapper/json-record-instance.mapper";
import { RecordInstanceDTO } from "../../../../record/data/models/recordInstance/recordInstance.dto";
import { SyncSinceResponse } from "../../../../shared/infrastructure/model/sync-since-response.model";
import { SyncSinceRequest } from "../../../../shared/infrastructure/model/sync-since-request.model";

/**
 * Intercepts HTTP requests and responses which are related to record and transforms the JSON objects to DTO
 * @param request The request to be intercepted.
 * @param next The next interceptor in the chain.
 * @returns The observable of the HTTP event.
 */

const allowedRoutes = [environment.routes.mCase.routes.syncSince.url];

export const syncSinceInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const jsonRecordInstanceMapper: JsonRecordInstanceMapper = inject(
        JsonRecordInstanceMapper
    );

    /**
     * Transforms the JSON object to a DTO object.
     * @param responseBody The JSON object to be transformed.
     * @returns The transformed DTO object.
     */
    const transformResponseToDTO = (responseBody: any): SyncSinceResponse => {
        const dtoRecords: RecordInstanceDTO[] = [];
        (responseBody.Records as []).forEach((record) => {
            dtoRecords.push(jsonRecordInstanceMapper.mapFrom(record));
        });

        const updatedResponse: SyncSinceResponse = {
            synchronizeAgain: responseBody.SyncAgain,
            lastSynchronizationId: responseBody.LastSyncedActivityID,
            records: dtoRecords,
            maxVersion: responseBody.maxVersion,
        };

        return updatedResponse;
    };

    const transformRequestDTOToJSON = (requestBody: SyncSinceRequest): any => {
        return {
            lastSyncedActivityID: requestBody.lastSynchronizationId,
            RecordIds: requestBody.recordIds,
            maxVersion: requestBody.maxVersion,
        };
    };

    if (allowedRoutes.some((route) => request.url.includes(route))) {
        request = request.clone({
            body: transformRequestDTOToJSON(request.body as SyncSinceRequest),
        });
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule

            if (!(response instanceof HttpResponse) || !response.url) {
                return response;
            }

            const responseUrl = response.url;
            if (!allowedRoutes.some((route) => responseUrl.includes(route))) {
                return response;
            }

            // Transform response body from JSON to DTO
            if (response instanceof HttpResponse) {
                response = response.clone({
                    body: transformResponseToDTO(
                        response.body as SyncSinceResponse
                    ),
                });
            }
            return response;
        })
    );
};
