import { InjectionToken } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DatadogUserMonitoringService } from "../services/datadog-user-monitoring.service";
import { IUserMonitoring } from "../models/user-monitoring.model";
import { UserMonitoringType } from "../../logging/models/enums/user-monitoring-type.enum";

/**
 * Injection token for the User monitoring service.
 */
export const USER_MONITORING = new InjectionToken<IUserMonitoring | null>(
    "USER_MONITORING",
    {
        providedIn: "root",
        factory: userMonitoringFactory,
    }
);

/**
 * Factory function to create an instance of a user monitoring service based on the user monitoring configuration.
 *
 */
function userMonitoringFactory() {
    switch (environment.userMonitoring) {
        case UserMonitoringType.Datadog:
            return new DatadogUserMonitoringService();

        default:
            return null;
    }
}
