import { inject, Injectable } from "@angular/core";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { DynamicMirrorDependencyOption } from "../../../record/core/domain/mirroring/dynamic-mirror-dependency-option";
import { MirrorDependency } from "../../../record/core/domain/mirroring/mirror-dependency";
import { ParentMirrorDependencyOption } from "../../../record/core/domain/mirroring/parent-mirror-dependency-option";
import { RegularMirrorDependencyOption } from "../../../record/core/domain/mirroring/regular-mirror-dependency-option";
import { UserMirrorDependencyOption } from "../../../record/core/domain/mirroring/user-mirror-dependency-option";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { DynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-dropdown-field-template.dto";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { MirrorFormulaConstants } from "../../domain/enums/mirror-formula-constants.enum";
import { MirrorOptionType } from "../../domain/enums/mirror-option-type.enum";
import { Graph } from "../data/model/templateGraph/graph";
import { Node } from "../data/model/templateGraph/node";
import { LOGGER } from "../../logging/providers/logger.provider";
import { MirrorFieldTemplateDTOArray } from "../../domain/enums/mirror-types";
import { ConfigurationErrorDetails } from "../../../inbox/core/domain/configuration-error-details.model";
import { ConfigurationErrorType } from "../../../inbox/core/domain/configuration-error-type.enum";
import { AddConfigurationErrorUsecase } from "../../../inbox/core/usecases/configuration-errors/add-configuration-error.usecase";
import { EmbeddedDocumentFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/embedded-document-field-template.dto";

@Injectable({
    providedIn: "root",
})
export class ComplexFieldValueDependencyService {
    private logger = inject(LOGGER);
    private _addConfigurationErrorUsecase = inject(
        AddConfigurationErrorUsecase
    );
    setupDependencies(graph: Graph<DatalistTemplateDTO>): void {
        graph.nodes.forEach((node: Node<DatalistTemplateDTO>) => {
            const mirrorFields = node.mirrorFields;
            mirrorFields.forEach((fieldSystemName: string) => {
                const mirrorField = node.fieldMap.get(fieldSystemName);

                if (!mirrorField) {
                    const mirrorFieldNotFoundErrorMessage = `Mirror Field ${fieldSystemName} not found for field ${fieldSystemName} on datalist ${node.data.datalistID}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.MirrorFieldNotFound,
                            mirrorFieldNotFoundErrorMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                try {
                    this.generateMirrorDependencyForFieldTemplate(
                        mirrorField,
                        graph
                    );
                } catch (error) {
                    const genericErrorMessage = `Error generating mirror dependency for field ${fieldSystemName} on datalist ${node.data.datalistID}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.MirrorFieldNotFound,
                            genericErrorMessage,
                            node.data.datalistID,
                            error
                        )
                    );
                }
            });

            // These are DDD fields
            const dynamicFields = node.dynamicFields;
            dynamicFields.forEach((fieldSystemName: string) => {
                const dynamicField = node.fieldMap.get(fieldSystemName);

                if (!dynamicField) {
                    const dynamicFieldNotFoundErrorMessage = `Dynamic Field ${fieldSystemName} not found on datalist ${node.data.datalistID}`;

                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.DynamicDropdownFieldNotFound,
                            dynamicFieldNotFoundErrorMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                const dynamicFieldSource = (
                    dynamicField as DynamicDropdownFieldTemplateDTO
                ).source;

                if (!dynamicFieldSource) {
                    const dynamicFieldSourceNotFoundErrorMessage = `Dynamic Field ${fieldSystemName} does not have a source defined on datalist ${node.data.datalistID}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.DynamicFieldSourceNotFound,
                            dynamicFieldSourceNotFoundErrorMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                const sourceDatalistTemplate =
                    graph.getNodeByID(dynamicFieldSource);
                // DDD datalist will be a parent to the current datalist
                if (!sourceDatalistTemplate) {
                    const sourceWorkspaceNotFoundMessage = `Source workspace not found for DDD field ${dynamicField.systemName} with source ${dynamicFieldSource}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.DynamicFieldSourceNotFound,
                            sourceWorkspaceNotFoundMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                graph.addEdge(sourceDatalistTemplate, node);
            });

            // Go over embedded document fields
            const embeddedDocumentFields = node.embeddedDocumentFields;
            embeddedDocumentFields.forEach((fieldID: number) => {
                const embeddedDocumentField =
                    node.fieldMapByFieldId.get(fieldID);

                // Field not found
                if (!embeddedDocumentField) {
                    const embeddedDocumentFieldNotFoundErrorMessage = `Embedded Document Field ${fieldID} not found on datalist ${node.data.datalistID}`;

                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.EmbeddedDocumentListNotFound,
                            embeddedDocumentFieldNotFoundErrorMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                const embeddedDocumentFieldSource = (
                    embeddedDocumentField as EmbeddedDocumentFieldTemplateDTO
                ).embeddedDocumentList;

                // Source not found
                if (!embeddedDocumentFieldSource) {
                    const embeddedDocumentFieldSourceNotFoundErrorMessage = `Embedded Document Field ${fieldID} does not have a source defined on datalist ${node.data.datalistID}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.EmbeddedDocumentListNotFound,
                            embeddedDocumentFieldSourceNotFoundErrorMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                // Get source datalist
                const sourceDatalistTemplate = graph.getNodeByID(
                    embeddedDocumentFieldSource
                );

                // Source datalist not found for embedded document field
                if (!sourceDatalistTemplate) {
                    const sourceWorkspaceNotFoundMessage = `Source workspace not found for Embedded Document Field ${embeddedDocumentField.systemName} with source ${embeddedDocumentFieldSource}`;
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.EmbeddedDocumentListNotFound,
                            sourceWorkspaceNotFoundMessage,
                            node.data.datalistID
                        )
                    );
                    return;
                }

                graph.addEdge(sourceDatalistTemplate, node);
            });
        });
    }

    generateMirrorDependencyForFieldTemplate(
        fieldTemplate: FieldTemplateDTO,
        graph: Graph<DatalistTemplateDTO>
    ): void {
        const fieldDefaultValue = fieldTemplate["defaultValue"];

        if (fieldDefaultValue == null || fieldDefaultValue === "") {
            return;
        }

        // Check if the default value indicates mirroring.
        const matches = fieldDefaultValue.match(
            /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
        );
        // No mirror matches found
        if (matches == null) {
            return;
        }
        // Initialize mirror dependency object
        const mirrorDependency = new MirrorDependency();
        const numberOfMatches = matches.length;
        // Mirror formula
        mirrorDependency.formula = fieldDefaultValue;
        if (numberOfMatches > 1) {
            mirrorDependency.formula = mirrorDependency.formula.substring(
                2,
                mirrorDependency.formula.length - 2
            );
            fieldTemplate.type = FieldTypes.MirrorString;
        }

        // Go over matches
        matches?.forEach((innerMatch: string) => {
            const matchParts = innerMatch.match(
                /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/
            );

            if (matchParts?.groups?.["reference"] != null) {
                const referencedDatalist = graph.getNodeByID(
                    fieldTemplate.datalistID
                );

                if (referencedDatalist == null) {
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.DatalistAccess,
                            `Referenced Datalist ${fieldTemplate.datalistID} for mirror field ${fieldTemplate.systemName} not found`,
                            fieldTemplate.datalistID
                        )
                    );
                    return;
                }

                // Reference can be DDD field or user
                if (
                    matchParts.groups?.["reference"] !=
                    MirrorFormulaConstants.parent
                ) {
                    const referencedFieldOnTemplate =
                        referencedDatalist.fieldMap.get(
                            matchParts.groups?.["reference"]
                        );

                    if (referencedFieldOnTemplate == null) {
                        const mirrorReferenceNotFound = `Mirror Parent Field ${matchParts.groups?.["reference"]} for field ${fieldTemplate.systemName}`;
                        this._addConfigurationErrorUsecase.execute(
                            new ConfigurationErrorDetails(
                                ConfigurationErrorType.DatalistAccess,
                                mirrorReferenceNotFound,
                                fieldTemplate.datalistID
                            )
                        );
                        return;
                    }

                    // The source field is dynamic dropdown.
                    if (
                        referencedFieldOnTemplate.type ===
                        FieldTypes.DynamicDropdown
                    ) {
                        this.logger.log(
                            `Dynamic Mirror Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is a DDD field`
                        );
                        // Field found. Add to dependency
                        const dynamicDTO: DynamicDropdownFieldTemplateDTO =
                            referencedFieldOnTemplate as DynamicDropdownFieldTemplateDTO;

                        if (
                            dynamicDTO.source &&
                            graph.getNodeByID(dynamicDTO.source) != null
                        ) {
                            const parent = graph.getNodeByID(dynamicDTO.source);
                            const dynamicReferenceFieldTemplate =
                                parent.fieldMap.get(
                                    matchParts.groups?.["systemName"]
                                );
                            if (!dynamicReferenceFieldTemplate) {
                                this._addConfigurationErrorUsecase.execute(
                                    new ConfigurationErrorDetails(
                                        ConfigurationErrorType.DynamicDropdownDependency,
                                        `Dynamic Mirror Field ${matchParts.groups?.["systemName"]} not found on datalist for field ${fieldTemplate.systemName}`,
                                        parent.data.datalistID
                                    )
                                );

                                return;
                            }
                            // Add type check
                            const mirrorDependencyField =
                                new DynamicMirrorDependencyOption();
                            mirrorDependencyField.fieldName =
                                referencedFieldOnTemplate.systemName;
                            mirrorDependencyField.fieldID =
                                referencedFieldOnTemplate.fieldID!;
                            mirrorDependencyField.mirrorType =
                                MirrorOptionType.Dynamic;
                            mirrorDependencyField.dynamicDatalistID =
                                parent.data.datalistID;
                            mirrorDependencyField.dynamicDatalistSystemName =
                                parent.data.systemName;
                            mirrorDependencyField.dynamicDatalistFieldID =
                                dynamicReferenceFieldTemplate.fieldID!;
                            mirrorDependencyField.dynamicDatalistFieldName =
                                dynamicReferenceFieldTemplate.systemName;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    }
                    // The source field is a user field
                    else if (
                        referencedFieldOnTemplate.type === FieldTypes.User
                    ) {
                        this.logger.log(
                            `Mirror User Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is an user field`
                        );
                        const mirrorDependencyField =
                            new UserMirrorDependencyOption();
                        mirrorDependencyField.fieldName =
                            referencedFieldOnTemplate.systemName;
                        mirrorDependencyField.fieldID =
                            referencedFieldOnTemplate.fieldID!;
                        mirrorDependencyField.mirrorType =
                            MirrorOptionType.User;
                        mirrorDependencyField.userPropertyName =
                            matchParts.groups?.["systemName"]; // TODO: UserData Object
                        mirrorDependencyField.mirrorLabel = innerMatch;
                        mirrorDependency.fields.push(mirrorDependencyField);
                    }
                }
                // Parent reference
                else if (
                    matchParts?.groups?.["reference"].toLowerCase() ===
                    MirrorFormulaConstants.parent
                ) {
                    const referenceSystemName =
                        matchParts?.groups?.["systemName"];
                    // Go over each parent dependency
                    referencedDatalist?.parentDependencies.forEach(
                        (parentNode: Node<DatalistTemplateDTO>) => {
                            if (
                                matchParts?.groups &&
                                !parentNode.fieldMap.has(referenceSystemName)
                            ) {
                                this._addConfigurationErrorUsecase.execute(
                                    new ConfigurationErrorDetails(
                                        ConfigurationErrorType.DynamicDropdownDependency,
                                        `Mirror Parent Field ${matchParts?.groups?.["systemName"]} not found on datalist ${parentNode.data.datalistID}`,
                                        parentNode.data.datalistID
                                    )
                                );
                                return;
                            }
                            const mirrorDependencyField =
                                new ParentMirrorDependencyOption();
                            mirrorDependencyField.parentDatalistFieldSystemName =
                                referenceSystemName; // Mirror source field name
                            mirrorDependencyField.parentDatalistID =
                                parentNode.data.datalistID;
                            mirrorDependencyField.parentDatalistSystemName =
                                parentNode.data.systemName;
                            mirrorDependencyField.parentDatalistFieldID =
                                parentNode.fieldMap.get(
                                    referenceSystemName
                                )!.fieldID!;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    );
                }
            }
            // Reference not found. Simple mirror
            else if (matchParts?.groups?.["systemName"] != null) {
                const mirrorDependencyField =
                    new RegularMirrorDependencyOption();
                mirrorDependencyField.fieldName =
                    matchParts?.groups?.["systemName"];
                // TODO: Clean this up
                mirrorDependencyField.fieldID = graph
                    .getNodeByID(fieldTemplate.datalistID)
                    .fieldMap.get(matchParts?.groups?.["systemName"])!.fieldID!;
                mirrorDependencyField.mirrorType = MirrorOptionType.Regular;
                mirrorDependencyField.mirrorLabel = innerMatch;
                mirrorDependency.fields.push(mirrorDependencyField);
            } else {
                // TODO: COAELSCE
            }
        });
        (fieldTemplate as MirrorFieldTemplateDTOArray).valueDependency =
            mirrorDependency;
    }
}
