import { addEventListener, Observable, setTimeout, clearTimeout, monitor, includes } from '@datadog/browser-core';
import { hasValidResourceEntryDuration, isAllowedRequestUrl } from '../domain/resource/resourceUtils';
import { retrieveFirstInputTiming } from './firstInputPolyfill';
// We want to use a real enum (i.e. not a const enum) here, to be able to check whether an arbitrary
// string is an expected performance entry
// eslint-disable-next-line no-restricted-syntax
export var RumPerformanceEntryType;
(function (RumPerformanceEntryType) {
  RumPerformanceEntryType["EVENT"] = "event";
  RumPerformanceEntryType["FIRST_INPUT"] = "first-input";
  RumPerformanceEntryType["LARGEST_CONTENTFUL_PAINT"] = "largest-contentful-paint";
  RumPerformanceEntryType["LAYOUT_SHIFT"] = "layout-shift";
  RumPerformanceEntryType["LONG_TASK"] = "longtask";
  RumPerformanceEntryType["LONG_ANIMATION_FRAME"] = "long-animation-frame";
  RumPerformanceEntryType["NAVIGATION"] = "navigation";
  RumPerformanceEntryType["PAINT"] = "paint";
  RumPerformanceEntryType["RESOURCE"] = "resource";
})(RumPerformanceEntryType || (RumPerformanceEntryType = {}));
export function createPerformanceObservable(configuration, options) {
  return new Observable(function (observable) {
    if (!window.PerformanceObserver) {
      return;
    }
    var handlePerformanceEntries = function (entries) {
      var rumPerformanceEntries = filterRumPerformanceEntries(entries);
      if (rumPerformanceEntries.length > 0) {
        observable.notify(rumPerformanceEntries);
      }
    };
    var timeoutId;
    var isObserverInitializing = true;
    var observer = new PerformanceObserver(monitor(function (entries) {
      // In Safari the performance observer callback is synchronous.
      // Because the buffered performance entry list can be quite large we delay the computation to prevent the SDK from blocking the main thread on init
      if (isObserverInitializing) {
        timeoutId = setTimeout(function () {
          return handlePerformanceEntries(entries.getEntries());
        });
      } else {
        handlePerformanceEntries(entries.getEntries());
      }
    }));
    try {
      observer.observe(options);
    } catch (_a) {
      // Some old browser versions (<= chrome 74 ) don't support the PerformanceObserver type and buffered options
      // In these cases, fallback to getEntriesByType and PerformanceObserver with entryTypes
      // TODO: remove this fallback in the next major version
      var fallbackSupportedEntryTypes = [RumPerformanceEntryType.RESOURCE, RumPerformanceEntryType.NAVIGATION, RumPerformanceEntryType.LONG_TASK, RumPerformanceEntryType.PAINT];
      if (includes(fallbackSupportedEntryTypes, options.type)) {
        if (options.buffered) {
          timeoutId = setTimeout(function () {
            return handlePerformanceEntries(performance.getEntriesByType(options.type));
          });
        }
        try {
          observer.observe({
            entryTypes: [options.type]
          });
        } catch (_b) {
          // Old versions of Safari are throwing "entryTypes contained only unsupported types"
          // errors when observing only unsupported entry types.
          //
          // We could use `supportPerformanceTimingEvent` to make sure we don't invoke
          // `observer.observe` with an unsupported entry type, but Safari 11 and 12 don't support
          // `Performance.supportedEntryTypes`, so doing so would lose support for these versions
          // even if they do support the entry type.
          return;
        }
      }
    }
    isObserverInitializing = false;
    manageResourceTimingBufferFull(configuration);
    var stopFirstInputTiming;
    if (!supportPerformanceTimingEvent(RumPerformanceEntryType.FIRST_INPUT) && options.type === RumPerformanceEntryType.FIRST_INPUT) {
      ;
      stopFirstInputTiming = retrieveFirstInputTiming(configuration, function (timing) {
        handlePerformanceEntries([timing]);
      }).stop;
    }
    return function () {
      observer.disconnect();
      if (stopFirstInputTiming) {
        stopFirstInputTiming();
      }
      clearTimeout(timeoutId);
    };
  });
}
var resourceTimingBufferFullListener;
function manageResourceTimingBufferFull(configuration) {
  if (!resourceTimingBufferFullListener && supportPerformanceObject() && 'addEventListener' in performance) {
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1559377
    resourceTimingBufferFullListener = addEventListener(configuration, performance, 'resourcetimingbufferfull', function () {
      performance.clearResourceTimings();
    });
  }
  return function () {
    resourceTimingBufferFullListener === null || resourceTimingBufferFullListener === void 0 ? void 0 : resourceTimingBufferFullListener.stop();
  };
}
function supportPerformanceObject() {
  return window.performance !== undefined && 'getEntries' in performance;
}
export function supportPerformanceTimingEvent(entryType) {
  return window.PerformanceObserver && PerformanceObserver.supportedEntryTypes !== undefined && PerformanceObserver.supportedEntryTypes.includes(entryType);
}
function filterRumPerformanceEntries(entries) {
  return entries.filter(function (entry) {
    return !isForbiddenResource(entry);
  });
}
function isForbiddenResource(entry) {
  return entry.entryType === RumPerformanceEntryType.RESOURCE && (!isAllowedRequestUrl(entry.name) || !hasValidResourceEntryDuration(entry));
}
