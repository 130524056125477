/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from "@angular/core";
import { LOGGER } from "../../logging/providers/logger.provider";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { MirrorFieldMap } from "../../domain/enums/mirror-types";
import { HttpEvent } from "@angular/common/http";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { ClientSideEventTemplateDTO } from "../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { ClientWorkflowOptionsDTO } from "../../../record/data/models/datalist/clientEvents/client-workflow-options.dto";
import { CombinedGridOptionTemplateDTO } from "../../../record/data/models/datalist/combined-grid-option-template.dto";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { IconTemplateDTO } from "../../../record/data/models/datalist/icon-template.dto";
import { LabelTemplateDTO } from "../../../record/data/models/datalist/label-template.dto";
import { OptionTemplateDTO } from "../../../record/data/models/datalist/option-template.dto";
import { SearchableDescendantListsTemplateDTO } from "../../../record/data/models/datalist/searchable-descendant-lists-template.dto";
import { HeaderFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/header-field-template.dto";
import { SectionFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/section-field-template.dto";
import { ConditionallyMandatoryDTO } from "../../../record/data/models/options/conditionally-mandatory.dto";
import { FieldDependencyOptionDTO } from "../../data/model/fieldDependencyOption/field-dependency-option.dto";
import { FieldDependencyDTO } from "../../data/model/fieldDependencyOption/field-dependency.dto";
import { FieldValidatorDTO } from "../../data/model/fieldValidator/fieldValidator.dto";
import { CascadingDropdownFieldValue } from "../../domain/dropdownValues/cascading-drop-down-field-value.model";
import { DropdownFieldValue } from "../../domain/dropdownValues/drop-down-field-value.model";
import { ComparisonOperator } from "../../domain/enums/comparison-operator.enum";
import { FieldValidators } from "../../domain/enums/field-validators.enum";
import { SoundsLikeMode } from "../../domain/enums/sounds-like-mode.enum";
import { Graph } from "../data/model/templateGraph/graph";
import { Node } from "../data/model/templateGraph/node";
import { ColumnWidthDisplayOptions } from "../../../record/data/models/options/column-width-display-options.enum";
import { FieldSearchConfiguration } from "../../domain/search/default-search-configuration";
import { BooleanUtilityMethods } from "../../../shared/utilities/type-extensions/boolean-utility-methods.service";
import { InterceptorUtilityMethods } from "../../infrastructure/utility/interceptor-utility-methods";
import { PrintSize } from "../../../record/data/models/options/print-size.enum";
import { ComplexFieldValueDependencyService } from "./complex-field-value-dependency.service";
import { DateRestrictions } from "../../domain/enums/date-restrictions.enum";
import { DatalistRelationshipTemplateDTO } from "../../../record/data/models/datalist/childDatalistRelationship/datalist-relationship-template.dto";
import { CalculatedFieldTransformService } from "./calculated-field-transform.service";
import { DynamicCalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-calculated-field-template.dto";
import { CalculationOperations } from "../../domain/enums/calculation-operations.enum";
import {
    dateSet,
    datetimeSet,
    numberSet,
} from "../../domain/enums/calculated-types";
import { UserRole } from "../../fields/user-roles-control/data/user-role";
import { ConfigurationErrorDetails } from "../../../inbox/core/domain/configuration-error-details.model";
import { ConfigurationErrorType } from "../../../inbox/core/domain/configuration-error-type.enum";
import { SortDirection } from "../../domain/enums/sort-direction.enum";
import { AddConfigurationErrorUsecase } from "../../../inbox/core/usecases/configuration-errors/add-configuration-error.usecase";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ServerTemplateParseService {
    private _addConfigurationErrorUsecase = inject(
        AddConfigurationErrorUsecase
    );
    private _id: number = -1;
    /**
     * Get next id for default section or headers.This is needed for proper parent header and section assignment.
     * `0` we used does not guarantee uniqueness.
     */
    get nextId(): number {
        return --this._id;
    }
    logger = inject(LOGGER);
    interceptorUtilityMethods = inject(InterceptorUtilityMethods);
    complexFieldValueDependencyService = inject(
        ComplexFieldValueDependencyService
    );
    calculatedFieldTransformService = inject(CalculatedFieldTransformService);
    isDefaultValueMirror(fieldTemplate: any): boolean {
        if (
            Object.prototype.hasOwnProperty.call(
                fieldTemplate,
                "defaultValue"
            ) &&
            typeof fieldTemplate.defaultValue !== "undefined" &&
            fieldTemplate.defaultValue !== null &&
            fieldTemplate.defaultValue !== "" //skip empty string
        ) {
            //May be a mirror if default value contains start and end marker
            return (
                fieldTemplate.defaultValue.includes("{[") &&
                fieldTemplate.defaultValue.includes("]}")
            );
        }

        return false;
    }

    fieldMap = new Map([
        ["address", FieldTypes.Address],
        ["attachment", FieldTypes.Attachment],
        ["boolean", FieldTypes.Boolean],
        ["calculatedfield", FieldTypes.CalculatedField],
        ["cascadingdropdown", FieldTypes.CascadingDropdown],
        ["cascadingdynamicdropdown", FieldTypes.CascadingDynamicDropdown],
        ["checkbox", FieldTypes.Checkbox],
        ["datalist", FieldTypes.Datalist],
        ["date", FieldTypes.Date],
        ["datetime", FieldTypes.DateTime],
        ["dropdown", FieldTypes.Dropdown],
        ["dropdownlist", FieldTypes.Dropdown],
        ["dynamiccalculatedfield", FieldTypes.DynamicCalculatedField],
        ["dynamicdropdown", FieldTypes.DynamicDropdown],
        ["emailaddress", FieldTypes.EmailAddress],
        ["embeddeddocument", FieldTypes.EmbeddedDocument],
        ["embeddedlist", FieldTypes.EmbeddedList],
        ["externalobject", FieldTypes.ExternalObject],
        ["longstring", FieldTypes.LongString],
        ["linebreak", FieldTypes.LineBreak],
        ["maxscore", FieldTypes.MaxScore],
        ["minscore", FieldTypes.MinScore],
        ["money", FieldTypes.Money],
        ["narrative", FieldTypes.Narrative],
        ["number", FieldTypes.Number],
        ["phone", FieldTypes.Phone],
        ["readonlyfield", FieldTypes.ReadOnly],
        ["score1", FieldTypes.Score],
        ["score2", FieldTypes.Score],
        ["score3", FieldTypes.Score],
        ["score4", FieldTypes.Score],
        ["score5", FieldTypes.Score],
        ["score6", FieldTypes.Score],
        ["string", FieldTypes.String],
        ["time", FieldTypes.Time],
        ["uniqueidentifier", FieldTypes.UniqueIdentifier],
        ["url", FieldTypes.Url],
        ["user", FieldTypes.User],
        ["userrolesecurityrestrict", FieldTypes.Urbs],
        ["userroles", FieldTypes.UserRoles],
    ]);
    transformFieldType(fieldTemplate: any): string {
        let fieldType = fieldTemplate.addType;
        // Remove spaces
        fieldType = fieldType.replace(/\s/g, "");
        fieldType = fieldType.replace(/-/g, "");

        if (this.fieldMap.has(fieldType.toLowerCase())) {
            fieldType = this.fieldMap.get(fieldType.toLowerCase());

            if (fieldType.toLowerCase().startsWith("score")) {
                this.updateScoreFieldTemplate(fieldTemplate);
            }
        } else {
            this._addConfigurationErrorUsecase.execute(
                new ConfigurationErrorDetails(
                    ConfigurationErrorType.FieldTypeNotFound,
                    `Field type ${fieldType} not found`,
                    fieldTemplate.datalistID
                )
            );
        }

        if (this.isDefaultValueMirror(fieldTemplate)) {
            // mirror field type
            const mirrorFieldType = MirrorFieldMap.get(fieldType);

            if (!mirrorFieldType) {
                this._addConfigurationErrorUsecase.execute(
                    new ConfigurationErrorDetails(
                        ConfigurationErrorType.MirrorFieldNotFound,
                        `Mirror Field type ${fieldType} not found`,
                        fieldTemplate.datalistID
                    )
                );
            } else {
                fieldType = mirrorFieldType;
            }
        }
        return fieldType;
    }

    updateScoreFieldTemplate = (fieldTemplate: any): void => {
        const scoreMatch = fieldTemplate.addType.match(/\d+/);
        if (scoreMatch != null) {
            fieldTemplate.scoreIdentifier = parseInt(scoreMatch[0]);
        }
    };

    transformResponseToDatalistDTO(
        responseBody: HttpEvent<any>,
        graph: Graph<DatalistTemplateDTO>
    ): any {
        // Create a new Datalist DTO object
        const template: DatalistTemplateDTO = new DatalistTemplateDTO();

        // Clone response body
        const responseBodyCopy = { ...responseBody };
        const templateListID = Object.entries(responseBodyCopy).find(
            ([key, value]) => {
                return key === "listId";
            }
        );

        if (!templateListID) {
            throw new Error(`List ID not found in response body`);
        }

        template.datalistID = templateListID[1];
        // Go over each property in the response body
        Object.entries(responseBodyCopy).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                return;
            }
            if (key === "optionTemplate") {
                template.optionTemplate =
                    this.transformDataToOptionTemplateDTO(value);
            } else if (key === "labelTemplate") {
                // Label Template
                template.labelTemplate = Object.assign(
                    new LabelTemplateDTO(),
                    value
                );
            } else if (key === "children") {
                // Children
                template.children = value.map((child: any) => {
                    for (const prop in child) {
                        if (Object.prototype.hasOwnProperty.call(child, prop)) {
                            const camelCaseProp =
                                prop[0].toLowerCase() + prop.slice(1);
                            if (camelCaseProp != prop) {
                                child[camelCaseProp] = child[prop];
                                delete child[prop];
                            }
                        }
                    }
                    const childDatalistRelationshipTemplateDTO = Object.assign(
                        new DatalistRelationshipTemplateDTO(),
                        child
                    );
                    // graph.addEdge(graph.getNodeByID(template.datalistID), graph.getNodeByID(childDatalistRelationshipTemplateDTO.childListID));
                    // graph.getNodeByID
                    return childDatalistRelationshipTemplateDTO;
                });
            } else if (key === "listLabel") {
                template.name = value;
            } else if (key === "dataListDescription") {
                template.description = value;
            } else if (key === "listId") {
                template.datalistID = value;
            } else if (key === "sortOrder") {
                template.sortOrder = value;
            } else if (key === "readOnlyChildren") {
                template.readOnlyChildren = value;
            } else if (key === "checksum") {
                template.checksum = value;
            } else if (key === "allowAdd") {
                template.allowAdd =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowModify") {
                template.allowModify =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowDelete") {
                template.allowDelete =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowMove") {
                template.allowMove =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowActivityWall") {
                template.allowActivityWall =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowMerge") {
                template.allowMerge =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowEditOthers") {
                template.allowEditOthers =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowEditOthersDraft") {
                template.allowEditOthersDraft =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "isListAdmin") {
                template.isListAdmin =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "isInfrastructureList") {
                template.isInfrastructureList =
                    BooleanUtilityMethods.convertToBoolean(value);
            }
            // else if (key === "elementAccessEvaluated") {
            //     template.elementAccessEvaluated = value;
            // }
            else if (key === "adminInfo") {
                // TODO: Separate transformation might be needed
                template.adminInfo = value;
            } else if (key === "systemUsers") {
                template.systemUsers = value;
            } else if (key === "clientSideEventTemplates") {
                // TODO: Separate transformation might be needed
                const clientSideEventTemplates: ClientSideEventTemplateDTO[] =
                    value.map((clientSideEventTemplate: any) => {
                        return this.transformDataToClientSideEventTemplateDTO(
                            clientSideEventTemplate
                        );
                    });

                template.clientSideEventTemplates = clientSideEventTemplates;
            } else if (key === "searchableDescendantLists") {
                // TODO: Separate transformation might be needed
                const searchableDescendantLists: SearchableDescendantListsTemplateDTO[] =
                    value.map((searchableDescendantList: any) => {
                        return this.transformDataToSearchableDescendantListsTemplate(
                            searchableDescendantList
                        );
                    });

                template.searchableDescendantLists =
                    searchableDescendantLists as SearchableDescendantListsTemplateDTO[];
            } else if (key === "systemName") {
                template.systemName = value;
            }
        });

        graph.addNode(new Node<DatalistTemplateDTO>(template));
        /**
         * We go over sections and fields separately and after option template and label template
         * have been resolved. This is because we need to determine show in header fields.
         */
        const sectionsKV = Object.entries(responseBodyCopy).find(
            ([key, value]) => {
                return key === "sections";
            }
        );

        if (!sectionsKV) {
            throw new Error(`Sections not found in response body`);
        }

        const key = sectionsKV[0];
        const value = sectionsKV[1];

        if (key && value) {
            value.forEach((val: any) => {
                val.datalistID = template.datalistID;
            });
            // Create our section => header => field template structure
            template.sections = this.transformDataToSectionTemplateDTO(
                value,
                graph
            );
        } else {
            throw new Error(`Sections not found in response body`);
        }

        return template;
    }

    /**
     * https://github.com/RedMane/mCase.Phoenix/issues/656
     * TODO: change approach on the property processing. Right now we are doing guessing game.
     * Expected properties should be in the map lowerCaseProperty: string > desiredPropertyName: string
     * Iterate over properties and process only those that are defined in the map.
     */

    transformDataToSectionTemplateDTO(
        value: any,
        graph: Graph<DatalistTemplateDTO>
    ): SectionFieldTemplateDTO[] {
        return value.map((section: any) => {
            //Need to process dependencies for section
            this.transformDataToFieldTemplateDTO(section, graph);
            // Go over each property in a section
            for (const prop in section) {
                //Skipping fieldId/fieldID processing - seems to be prematurely cleared pout messing up the fieldID below
                if (prop.toLocaleLowerCase() === "fieldid") continue;
                // Camel case conversion
                if (Object.prototype.hasOwnProperty.call(section, prop)) {
                    const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                    if (camelCaseProp != prop) {
                        section[camelCaseProp] = section[prop];
                        delete section[prop];
                    }
                    // Create headerFieldTemplateDTO
                    if (camelCaseProp === "headers") {
                        const headers: HeaderFieldTemplateDTO[] =
                            this.transformDataToHeaderFieldTemplateDTO(
                                section,
                                graph
                            );

                        delete section.headers;
                        section.children = headers;
                    }
                }
            }

            section.fieldID = section.fieldID ?? section.fieldId ?? this.nextId;
            // Remove older reference
            delete section.fieldId;
            return Object.assign(new SectionFieldTemplateDTO(), section);
        });
    }
    transformDataToHeaderFieldTemplateDTO(
        section: any,
        graph: Graph<DatalistTemplateDTO>
    ): HeaderFieldTemplateDTO[] {
        const headers: HeaderFieldTemplateDTO[] = [];

        section["headers"].map((header: any) => {
            header.datalistID = section.datalistID;
            //Need to process dependencies for header
            this.transformDataToFieldTemplateDTO(header, graph);
            for (const headerProp in header) {
                //Skipping fieldId/fieldID processing - seems to be prematurely cleared pout messing up the fieldID below
                if (headerProp.toLocaleLowerCase() === "fieldid") continue;
                if (Object.prototype.hasOwnProperty.call(header, headerProp)) {
                    const camelCaseHeaderProp =
                        headerProp[0].toLowerCase() + headerProp.slice(1);
                    if (camelCaseHeaderProp != headerProp) {
                        header[camelCaseHeaderProp] = header[headerProp];
                        delete header[headerProp];
                    }

                    // Go over fieldTemplates
                    if (camelCaseHeaderProp === "fieldTemplates") {
                        let fieldTemplates = header[camelCaseHeaderProp];

                        // Filter out some fields. TODO: Create a new list of field types to filter out
                        const filteredTemplates = fieldTemplates.filter(
                            (ft: any) =>
                                ft.addType != "OLFSAttachment" &&
                                ft.addType != "Intake Referral ID" &&
                                ft.addType != "Current Date" &&
                                ft.addType != "Current User" &&
                                ft.addType != "Read-only Field" &&
                                ft.addType != "Hidden Field"
                        );

                        fieldTemplates = filteredTemplates.map(
                            (fieldTemplate: any) => {
                                fieldTemplate.datalistID = section.datalistID;
                                const transformedFieldTemplate =
                                    this.transformDataToFieldTemplateDTO(
                                        fieldTemplate,
                                        graph
                                    );
                                graph
                                    .getNodeByID(fieldTemplate.datalistID)
                                    ?.addToFieldMap(transformedFieldTemplate);
                                return transformedFieldTemplate;
                            }
                        );

                        // Confirm if the type is correct
                        header.children = fieldTemplates;
                        delete header.fieldTemplates;
                    }
                }
            }
            header.fieldID = header.fieldID ?? header.fieldId ?? this.nextId;
            delete header.fieldId;
            const newHeader: HeaderFieldTemplateDTO = Object.assign(
                new HeaderFieldTemplateDTO(),
                header
            );
            headers.push(newHeader);
        });

        return headers;
    }

    /**
     * Transforms the given field template object into a FieldTemplateDTO object.
     *
     * @param fieldTemplate - The field template object to transform.
     * @returns The transformed FieldTemplateDTO object.
     */
    transformDataToFieldTemplateDTO(
        fieldTemplate: any,
        graph: Graph<DatalistTemplateDTO>
    ): FieldTemplateDTO {
        // Initialize field dependency object. This will be used to store the field dependency information.
        const fieldDependency = new FieldDependencyDTO();
        fieldTemplate.validators = [];

        fieldTemplate.fieldID = fieldTemplate.fieldId;
        // Go over each property in the field template object
        for (const fieldProp in fieldTemplate) {
            // Redundant check
            if (
                Object.prototype.hasOwnProperty.call(fieldTemplate, fieldProp)
            ) {
                // Camel case conversion
                const camelCaseFieldProp =
                    fieldProp[0].toLowerCase() + fieldProp.slice(1);
                //#region Field Dependencies
                if (camelCaseFieldProp === "allDependenciesMustPass") {
                    fieldDependency.allDependenciesMustPass =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "displayOption" ||
                    camelCaseFieldProp === "Displayoption"
                ) {
                    // enum conversion
                    fieldDependency.displayOption = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "items") {
                    // Transform per field type
                    this.transformItemsToFieldTemplateDTOOptions(fieldTemplate);
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "dependencies") {
                    // List of dependencies
                    const dependencies: FieldDependencyOptionDTO[] =
                        this.transformDependenciesToFieldDependencyOptionDTO(
                            fieldTemplate
                        );
                    fieldDependency.dependencies = dependencies;
                }
                //#endregion
                //#region Validations
                else if (camelCaseFieldProp === "validations") {
                    const validators: FieldValidatorDTO[] =
                        this.transformValidationsToFieldValidatorDTO(
                            fieldTemplate
                        );

                    fieldTemplate.validators.push(...validators);
                    delete fieldTemplate[fieldProp];
                }
                //#endregion
                //#region Field Options
                else if (camelCaseFieldProp === "fieldOptions") {
                    this.transformFieldOptions(fieldTemplate);
                }
                //#endregion
                else if (camelCaseFieldProp === "label") {
                    fieldTemplate.label = fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "addType" &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate.type = this.transformFieldType(fieldTemplate);
                    delete fieldTemplate["addType"];
                } else if (
                    camelCaseFieldProp === "editType" &&
                    fieldTemplate[fieldProp]
                ) {
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "viewType") {
                    delete fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null &&
                    fieldTemplate[fieldProp].match(
                        /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
                    ) != null
                ) {
                    graph
                        .getNodeByID(fieldTemplate.datalistID)
                        ?.mirrorFields.add(fieldTemplate.systemName);
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null
                ) {
                    fieldTemplate.defaultValue = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "adminOnlyInd") {
                    fieldTemplate.adminOnly =
                        BooleanUtilityMethods.convertToBoolean(
                            fieldTemplate[fieldProp]
                        );
                    delete fieldTemplate[fieldProp];
                }
                // Rest of the properties
                if (
                    camelCaseFieldProp != fieldProp &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate[camelCaseFieldProp] =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                }
            }
        }
        delete fieldTemplate["dependencies"];
        delete fieldTemplate["allDependenciesMustPass"];
        delete fieldTemplate["displayOption"];
        delete fieldTemplate["DisplayOption"];

        // Only add if there are dependencies defined on the field template
        if (fieldDependency?.dependencies?.length > 0) {
            fieldTemplate.visibilityDependency = fieldDependency;
        }
        // Update show
        this.updateShowInHeaderFieldTemplateDTO(fieldTemplate, graph);
        delete fieldTemplate["fieldId"];
        return fieldTemplate;
    }

    updateShowInHeaderFieldTemplateDTO = (
        fieldTemplate: FieldTemplateDTO,
        graph: Graph<DatalistTemplateDTO>
    ) => {
        const templateNode = graph.getNodeByID(fieldTemplate.datalistID);
        if (templateNode == null) {
            return;
        }
        const optionTemplate = templateNode.data.optionTemplate;

        if (optionTemplate && optionTemplate.recordHeaderColumns.length > 0) {
            fieldTemplate.showInHeader =
                optionTemplate.recordHeaderColumns.some(
                    (fieldID) => fieldID === fieldTemplate.fieldID
                );
        } else {
            const labelTemplate = templateNode.data.labelTemplate;
            fieldTemplate.showInHeader = labelTemplate.fields?.some(
                (fieldID) => fieldID === fieldTemplate.fieldID
            );
        }
    };

    transformValidationsToFieldValidatorDTO(
        fieldTemplate: any
    ): FieldValidatorDTO[] {
        const validations = fieldTemplate["validations"].map(
            (validator: any) => {
                const fieldValidator = new FieldValidatorDTO();
                for (const validatorProp in validator) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            validator,
                            validatorProp
                        )
                    ) {
                        const camelCaseValidatorProp =
                            validatorProp[0].toLowerCase() +
                            validatorProp.slice(1);
                        if (camelCaseValidatorProp === "regularExpression") {
                            fieldValidator.validationValue =
                                validator[validatorProp];
                        } else if (camelCaseValidatorProp === "errorMessage") {
                            fieldValidator.validationMessage =
                                validator[validatorProp];
                            if (validator[validatorProp].includes("Exceeds")) {
                                // Long string max. Need some better way to determine this
                                fieldValidator.validationType =
                                    FieldValidators.LongStringMax;
                            } else {
                                fieldValidator.validationType =
                                    FieldValidators.Regex;
                            }
                        }
                        if (camelCaseValidatorProp != validatorProp) {
                            validator[camelCaseValidatorProp] =
                                validator[validatorProp];
                            delete validator[validatorProp];
                        }
                    }
                }

                return fieldValidator;
            }
        );
        // Add required validator if required
        if (fieldTemplate.required) {
            const requiredValidator = new FieldValidatorDTO();

            requiredValidator.validationType =
                fieldTemplate.type === FieldTypes.DateTime ||
                fieldTemplate.type === FieldTypes.DateTime
                    ? FieldValidators.RequiredDate
                    : FieldValidators.Required;
            validations.push(requiredValidator);
        }
        return validations;
    }

    getEnumValue<T extends object>(
        enumType: T,
        value: T[keyof T]
    ): T[keyof T] | undefined {
        const enumValues = Object.values(enumType) as T[keyof T][];
        return enumValues.includes(value) ? value : undefined;
    }

    transformOptionToDefaultSearchConfiguration(
        fieldTemplate: any,
        propertyName: string,
        optionValue: any
    ) {
        if (!fieldTemplate.defaultSearchConfiguration) {
            fieldTemplate.defaultSearchConfiguration =
                new FieldSearchConfiguration();
        }

        fieldTemplate.defaultSearchConfiguration[propertyName] = optionValue;
    }

    transformOptionToAgeProperty(
        fieldTemplate: any,
        ageProperty: string,
        optionValue: any
    ) {
        if (!fieldTemplate.ageProperties) {
            fieldTemplate.ageProperties = {};
        }

        fieldTemplate.ageProperties[ageProperty] =
            BooleanUtilityMethods.convertToBoolean(optionValue);
    }
    transformFieldOptions(fieldTemplate: any) {
        const conditionallyMandatory = new ConditionallyMandatoryDTO();

        // Field Options
        fieldTemplate["fieldOptions"].forEach((fieldOption: any) => {
            const optionName = fieldOption["Key"];
            const optionValue = fieldOption["Value"];
            // Update option name to remove spaces and case correct.
            const modifiedOptionName = optionName
                .split(" ")
                .map((word: string, index: number) => {
                    // Lowercase the first word
                    if (index === 0) {
                        return word.toLowerCase();
                    }

                    // Capitalize the first letter of the rest of the words
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join("");

            switch (modifiedOptionName) {
                //#region Conditionally Mandatory
                case "conditionallyMandatory": // Create conditionally mandatory object
                    break;
                case "mandatedByField": {
                    const mandatedByFieldId = Number.parseInt(
                        fieldOption["Value"],
                        10
                    );
                    if (!isNaN(mandatedByFieldId)) {
                        conditionallyMandatory.mandatedByFieldId =
                            mandatedByFieldId;
                    }

                    break;
                }
                case "mandatedByValue":
                    conditionallyMandatory.mandatedByValue =
                        fieldOption["Value"];
                    break;
                case "mandatedIfFieldHasValue":
                    conditionallyMandatory.mandatedIfFieldHasValue =
                        BooleanUtilityMethods.convertToBoolean(
                            fieldOption["Value"]
                        );
                    break;

                //#endregion
                //#region Boolean Options
                case "showAge":
                case "allowUpdates":
                case "displayAsMatrix":
                case "hidden":
                case "hideLabel":
                case "cloneable":
                case "restrictOnPDF":
                case "disableCameraRoll":
                case "disableDrawingModal":
                case "disableFileAttachment":
                case "disableMicrophoneModal":
                case "disableSignatureModal":
                case "displayInitialOptions":
                case "hide360":
                case "hideAdd":
                case "hideSearch":
                case "recursiveSubscription":
                case "enableRange":
                case "enableSharing":
                case "deleteOnHide":
                case "preventEmbeddedAdd":
                case "preventEmbeddedEdit":
                case "preventEmbeddedDelete":
                case "defaultToNull":
                case "allowBulkEdit":
                case "summable":
                case "limitOptionsHeight":
                case "excludeDrafts":
                    fieldTemplate[modifiedOptionName] =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;

                case "rootOfParent":
                    fieldTemplate.dynamicRootIsParent =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;

                case "topLevelAsRoot":
                    fieldTemplate.dynamicRootIsTopLevel =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;

                //#endregion
                //#region Number Options
                case "indentLevel":
                case "embeddedDocumentList":
                case "embeddedDocumentSource":
                case "minimumValue":
                case "maximumValue":
                    fieldTemplate[modifiedOptionName] =
                        Number.parseInt(optionValue);
                    break;
                //#endregion
                //#region String Options
                case "fieldRestrictions":
                    fieldTemplate[modifiedOptionName] = optionValue;
                    break;
                //#endregion
                //#region Search Options
                case "defaultSearchType":
                case "defaultSearchValue":
                    this.transformOptionToDefaultSearchConfiguration(
                        fieldTemplate,
                        modifiedOptionName,
                        optionValue
                    );
                    break;
                case "applyOnQuickWorkspaceSearch":
                case "requiresSearchBeforeAdd":
                case "searchable":
                    this.transformOptionToDefaultSearchConfiguration(
                        fieldTemplate,
                        modifiedOptionName,
                        BooleanUtilityMethods.convertToBoolean(optionValue)
                    );
                    break;
                //#endregion
                case "simpleLongString":
                    fieldTemplate.isSimpleLongString =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "maxSize":
                    if (fieldTemplate.type === FieldTypes.Attachment) {
                        fieldTemplate.maxSize = Number.parseInt(optionValue);
                    } else {
                        fieldTemplate.maxLength = Number.parseInt(optionValue);
                    }
                    break;
                case "maskValue":
                    fieldTemplate.masked =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "displayFormat":
                    if (
                        (fieldTemplate.type === FieldTypes.DateTime ||
                            fieldTemplate.type === FieldTypes.Date ||
                            fieldTemplate.type === FieldTypes.MirrorDateTime ||
                            fieldTemplate.type === FieldTypes.MirrorDate) &&
                        optionValue
                    ) {
                        fieldTemplate.displayFormat = optionValue
                            .replaceAll("Y", "y")
                            .replaceAll("d", "c")
                            .replaceAll("D", "d")
                            .replaceAll("A", "a")
                            .replaceAll("Z", "ZZ")
                            .replaceAll("z", "ZZZZ");
                    } else {
                        fieldTemplate.displayFormat = optionValue;
                    }

                    break;

                case "enableAsCodeTable?":
                    fieldTemplate.enableAsCodeTable =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "ableToSelectMultipleValues":
                    fieldTemplate.multiSelect =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "displayAsRadioButtons":
                    fieldTemplate.displayAsRadio =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "displayHorizontally":
                    fieldTemplate.displayHorizontally =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "hideToolbar":
                    fieldTemplate.hidePdfToolbar =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "includeInPDF":
                    fieldTemplate.includeInPdf =
                        BooleanUtilityMethods.convertToBoolean(optionValue);
                    break;
                case "restrictedByField":
                    fieldTemplate.restrictedByFieldSystemName = optionValue;
                    break;
                case "defaultSoundsLike":
                    fieldTemplate.defaultSoundsLike = this.getEnumValue(
                        SoundsLikeMode,
                        optionValue
                    );
                    break;
                case "ageType":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "years",
                        true
                    );
                    if (optionValue === "Complex") {
                        this.transformOptionToAgeProperty(
                            fieldTemplate,
                            "months",
                            true
                        );
                    }
                    break;
                case "displayYears":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "years",
                        optionValue
                    );
                    break;
                case "displayMonths":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "months",
                        optionValue
                    );
                    break;
                case "displayDays":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "days",
                        optionValue
                    );
                    break;
                case "displayHours":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "hours",
                        optionValue
                    );
                    break;
                case "displayMinutes":
                    this.transformOptionToAgeProperty(
                        fieldTemplate,
                        "minutes",
                        optionValue
                    );
                    break;
                case "columnWidth":
                    fieldTemplate.columnWidth =
                        this.transformColumnWidthFieldOptionToColumnWidth(
                            modifiedOptionName,
                            optionValue
                        );
                    break;
                case "fieldMapping":
                    fieldTemplate.pdfFieldMapping = optionValue;
                    break;
                case "minEmbeddedInstances":
                    fieldTemplate.minEmbeddedCount =
                        Number.parseInt(optionValue);
                    break;
                case "maxEmbeddedInstances":
                    fieldTemplate.maxEmbeddedCount =
                        Number.parseInt(optionValue);
                    break;
                case "numberOfDecimalPlaces":
                    fieldTemplate.decimalPlaces = Number.parseInt(optionValue);
                    break;
                case "printSize":
                    fieldTemplate.printSize = this.getEnumValue(
                        PrintSize,
                        optionValue
                    );
                    break;
                case "sort":
                    fieldTemplate.sortDirection = this.getEnumValue(
                        SortDirection,
                        optionValue
                    );
                    break;
                default:
                    fieldTemplate[modifiedOptionName] = optionValue;
                    break;
            }

            if (!fieldTemplate.displayAsRadio) {
                //Ensure the displayHorizontally is false if the displayAsRadio is false
                fieldTemplate.displayHorizontally = false;
            }
        });

        delete fieldTemplate["fieldOptions"];
        if (Object.keys(conditionallyMandatory).length > 0) {
            fieldTemplate["conditionallyMandatory"] = conditionallyMandatory;
        }

        this.transformFieldOptionsToValidators(fieldTemplate);
    }

    private transformFieldOptionsToValidators(fieldTemplate: any) {
        if (fieldTemplate.restrictedByFieldSystemName) {
            const validator = new FieldValidatorDTO();
            validator.validationType = FieldValidators.RestrictedByField;
            fieldTemplate.validators.push(validator);
        }
        if (
            fieldTemplate.dateRestrictions &&
            (fieldTemplate.dateRestrictions === DateRestrictions.Future ||
                DateRestrictions.Past)
        ) {
            const validator = new FieldValidatorDTO();
            validator.validationValue = fieldTemplate.dateRestrictions;
            validator.validationType = FieldValidators.RestrictedDate;
            fieldTemplate.validators.push(validator);
        }
    }

    transformColumnWidthFieldOptionToColumnWidth(
        key: string,
        value?: string
    ): ColumnWidthDisplayOptions | undefined {
        if (key !== "columnWidth") {
            this.logger.warn("Wrong key for column width option");
            return;
        }

        switch (value) {
            case "Single":
                return ColumnWidthDisplayOptions.ColumnWidthSingle;
            case "Double":
                return ColumnWidthDisplayOptions.ColumnWidthDouble;
            case "Full":
                return ColumnWidthDisplayOptions.ColumnWidthFull;
            default:
                this.logger.warn(
                    "Column width not found. Defaulting to single"
                );
                return ColumnWidthDisplayOptions.ColumnWidthSingle;
        }
    }

    transformDependenciesToFieldDependencyOptionDTO(fieldTemplate: any) {
        const dependencies: FieldDependencyOptionDTO[] = [];
        // Go over each dependency
        fieldTemplate["dependencies"]?.map((dependency: any) => {
            const fieldDependencyOption = new FieldDependencyOptionDTO();
            // Go over each dependency property
            for (const dependencyProp in dependency) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        dependency,
                        dependencyProp
                    )
                ) {
                    const camelCaseDependencyProp =
                        dependencyProp[0].toLowerCase() +
                        dependencyProp.slice(1);
                    if (camelCaseDependencyProp === "dependentFieldID") {
                        // enum conversion
                        fieldDependencyOption.dependentFieldID =
                            dependency[dependencyProp];
                    } else if (
                        camelCaseDependencyProp === "dependentOnFieldID"
                    ) {
                        fieldDependencyOption.dependentOnFieldID =
                            dependency[dependencyProp];
                    } else if (
                        camelCaseDependencyProp === "fieldDependencyID"
                    ) {
                        fieldDependencyOption.fieldDependencyID =
                            dependency[dependencyProp];
                    } else if (camelCaseDependencyProp === "value") {
                        fieldDependencyOption.value =
                            dependency[dependencyProp];
                    } else if (camelCaseDependencyProp === "type") {
                        // Will this throw an error if not found? This should an exception?
                        fieldDependencyOption.type =
                            ComparisonOperator[
                                dependency[
                                    dependencyProp
                                ] as keyof typeof ComparisonOperator
                            ];
                    }

                    if (camelCaseDependencyProp != dependencyProp) {
                        dependency[camelCaseDependencyProp] =
                            dependency[dependencyProp];
                        delete dependency[dependencyProp];
                    }
                }
            }

            dependencies.push(fieldDependencyOption);
        });
        return dependencies;
    }

    transformItemsToFieldTemplateDTOOptions(fieldTemplate: any): any {
        const fieldTemplateItems: any[] = fieldTemplate.items;
        switch (fieldTemplate.type) {
            // This is for TODO, DataMover, etc. Not supported yet
            case FieldTypes.Datalist:
                break;
            case FieldTypes.User:
            case FieldTypes.MirrorUser:
                break;
            case FieldTypes.UserRoles:
                fieldTemplate.organizationRoles = new Map<number, UserRole>();
                fieldTemplateItems.forEach((item: any) => {
                    fieldTemplate.organizationRoles.set(item.value, {
                        id: item.value,
                        roleName: item.displayValue,
                    });
                });
                break;
            case FieldTypes.CascadingDynamicDropdown:
            case FieldTypes.DynamicDropdown:
                fieldTemplate.source =
                    fieldTemplateItems[0].source ?? undefined;
                fieldTemplate.root = fieldTemplateItems[0].root ?? undefined;
                break;
            case FieldTypes.DynamicCalculatedField:
                if (
                    environment.featureFlags
                        .DynamicCalculatedFieldClientSideCalculations
                ) {
                    fieldTemplate.source =
                        fieldTemplateItems[0].dynamicCalculatedSource;
                    fieldTemplate.root =
                        fieldTemplateItems[0].dynamicCalculatedRoot;
                    fieldTemplate.calculatedFieldId =
                        fieldTemplateItems[0].dynamicCalculatedFieldId;
                    fieldTemplate.calculation =
                        fieldTemplateItems[0].calculation;
                }
                break;
            case FieldTypes.MirrorCascadingDropdown:
            case FieldTypes.CascadingDropdown: {
                const cascadingDropdownValues: CascadingDropdownFieldValue[] =
                    [];
                fieldTemplateItems.forEach((item: any) => {
                    // Empty object check because of placeholder being sent by mCase
                    if (Object.keys(item).length === 0) return;

                    const dropdownValue = new CascadingDropdownFieldValue();
                    if (Object.prototype.hasOwnProperty.call(item, "parents")) {
                        dropdownValue.parent = item.parents ?? []; // This is an array. Length check?
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "value")) {
                        dropdownValue.value = item.value;
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item.displayValue;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "scores")) {
                        //For cascading dropdown fields scores are on the item Scores property
                        this.interceptorUtilityMethods.transformScores(
                            item.scores,
                            dropdownValue.scores
                        );

                        dropdownValue.backgroundColor =
                            item.scores.BackgroundColor;
                        dropdownValue.color = item.scores.Color;
                        dropdownValue.disabled = item.scores.IsDisabled;
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    cascadingDropdownValues.push(dropdownValue);
                });
                // TODO: Define concrete relationship between fieldTemplate and DropdownFieldValue
                fieldTemplate.options = cascadingDropdownValues;
                break;
            }
            case FieldTypes.MirrorDropdown:
            case FieldTypes.Dropdown: {
                const dropdownValues: DropdownFieldValue[] = [];
                fieldTemplateItems.forEach((item: any) => {
                    const dropdownValue = new DropdownFieldValue();
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "value")) {
                        dropdownValue.value = item.value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item["displayValue"];
                    }

                    //For dropdown fields scores are directly on the item value
                    this.interceptorUtilityMethods.transformScores(
                        item,
                        dropdownValue.scores
                    );
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(item, "IsDisabled")
                    ) {
                        dropdownValue.disabled = item.IsDisabled;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    dropdownValues.push(dropdownValue);
                });
                fieldTemplate.options = dropdownValues;
                break;
            }
            case FieldTypes.Boolean:
            case FieldTypes.MirrorBoolean: {
                const dropdownValue: DropdownFieldValue =
                    new DropdownFieldValue();
                //Process scores for Boolean fields
                //For boolean fields scores are in the first element of the items array

                if (
                    Object.hasOwn(fieldTemplate, "items") &&
                    Array.isArray(fieldTemplate.items) &&
                    fieldTemplate.items.length > 0
                ) {
                    const fieldTemplateItem = fieldTemplate.items[0];

                    this.interceptorUtilityMethods.transformScores(
                        fieldTemplateItem,
                        dropdownValue.scores
                    );
                }
                fieldTemplate.scoreInformation = dropdownValue;
                break;
            }
            case FieldTypes.CalculatedField:
                fieldTemplate.field1Id = fieldTemplateItems[0].field1;
                fieldTemplate.field2Id = fieldTemplateItems[0].field2;
                fieldTemplate.operation = fieldTemplateItems[0].operation;
                break;
            case FieldTypes.EmbeddedList:
                fieldTemplate.source = fieldTemplateItems.find(
                    (x) => x.source
                )?.source;
                break;
            case FieldTypes.EmbeddedDocument:
            case FieldTypes.Number:
            case FieldTypes.LongString:
            default:
                break;
        }
        // Regex possibly
    }

    transformTemplates(templates: any): any {
        const graph = new Graph<DatalistTemplateDTO>([]);

        if (templates == null || templates.length === 0) {
            this.logger.info("No templates found");
            return graph;
        }

        if (typeof templates == "object" && !Array.isArray(templates)) {
            templates = [templates];
        }

        const transformedTemplates: DatalistTemplateDTO[] = [];

        templates.forEach((template: any) => {
            try {
                const transformedTemplate = this.transformResponseToDatalistDTO(
                    template,
                    graph
                );
                transformedTemplates.push(transformedTemplate);
            } catch (error) {
                this._addConfigurationErrorUsecase.execute(
                    new ConfigurationErrorDetails(
                        ConfigurationErrorType.DatalistDeserialization,
                        `Datalist with ${template.listId} could not deserialized`,
                        template.datalistID,
                        error
                    )
                );
                this.logger.error(
                    `Error transforming template ${template.listId}`,
                    error
                );
            }
        });
        this.logger.debug(
            `Transformed ${transformedTemplates.length} templates`
        );
        // Add relationships
        transformedTemplates.forEach((template: DatalistTemplateDTO) => {
            // Relationships
            template.children.forEach(
                (child: DatalistRelationshipTemplateDTO) => {
                    if (
                        !graph.getNodeByID(template.datalistID) ||
                        !graph.getNodeByID(child.childListID)
                    ) {
                        return;
                    }
                    graph.addEdge(
                        graph.getNodeByID(template.datalistID),
                        graph.getNodeByID(child.childListID)
                    );
                    graph
                        .getNodeByID(child.childListID)
                        ?.addParentDependency(
                            graph.getNodeByID(template.datalistID)
                        );
                }
            );
        });

        this.logger.debug(`Templates transformed and relationships added`);
        this.complexFieldValueDependencyService.setupDependencies(graph);
        this.calculatedFieldTransformService.transformCalculatedFieldTypes(
            graph
        );
        this.transformDynamicCalculatedTypes(graph);

        this.logger.debug(`Graph created with ${graph.nodes.size} nodes`);
        return graph;
    }

    transformDynamicCalculatedTypes(graph: Graph<DatalistTemplateDTO>) {
        if (
            !environment.featureFlags
                .DynamicCalculatedFieldClientSideCalculations
        ) {
            return;
        }

        graph.nodes.forEach((node) => {
            node.dynamicCalculatedFields.forEach((field) => {
                field.readOnly = true;

                const dynamicCalculatedField =
                    field as DynamicCalculatedFieldTemplateDTO;

                //Dynamic Calculated count won't have a calculatedFieldId and is always a number
                if (
                    dynamicCalculatedField.calculation ===
                    CalculationOperations.Count
                ) {
                    field.type = FieldTypes.DynamicCalculatedNumber;
                    return;
                }

                const calculatedSourceField = graph
                    .getNodeByID(dynamicCalculatedField!.source)
                    .fieldMapByFieldId.get(
                        dynamicCalculatedField.calculatedFieldId as number
                    );

                if (!calculatedSourceField) {
                    this._addConfigurationErrorUsecase.execute(
                        new ConfigurationErrorDetails(
                            ConfigurationErrorType.DynamicCalculatedDependency,
                            `Dynamic calculated field could not be found for ${dynamicCalculatedField.systemName}`,
                            field.datalistID
                        )
                    );
                    return;
                }

                switch (true) {
                    case dateSet.has(calculatedSourceField.type):
                        field.type = FieldTypes.DynamicCalculatedDate;
                        break;
                    case datetimeSet.has(calculatedSourceField.type):
                        field.type = FieldTypes.DynamicCalculatedDateTime;
                        break;
                    case numberSet.has(calculatedSourceField.type):
                        field.type = FieldTypes.DynamicCalculatedNumber;
                        break;
                    default:
                        this._addConfigurationErrorUsecase.execute(
                            new ConfigurationErrorDetails(
                                ConfigurationErrorType.DynamicCalculatedDependency,
                                `Dynamic calculated field type not found for field ${dynamicCalculatedField.systemName}`,
                                field.datalistID
                            )
                        );
                        return;
                }
            });
        });
    }

    transformDataToClientSideEventTemplateDTO(
        clientSideEventTemplate: any
    ): ClientSideEventTemplateDTO {
        const newClientSideEventTemplate = new ClientSideEventTemplateDTO();
        for (const prop in clientSideEventTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    clientSideEventTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    clientSideEventTemplate[camelCaseProp] =
                        clientSideEventTemplate[prop];
                    delete clientSideEventTemplate[prop];
                }

                if (camelCaseProp === "clientWorkFlowOptions") {
                    const clientWorkflowOptions =
                        new ClientWorkflowOptionsDTO();
                    for (const clientWorkflowOptionProp in clientSideEventTemplate[
                        camelCaseProp
                    ]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                clientSideEventTemplate[camelCaseProp],
                                clientWorkflowOptionProp
                            )
                        ) {
                            const camelCaseClientWorkflowOptionProp =
                                clientWorkflowOptionProp[0].toLowerCase() +
                                clientWorkflowOptionProp.slice(1);
                            if (
                                camelCaseClientWorkflowOptionProp == "triggers"
                            ) {
                                clientWorkflowOptions.triggers =
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ];
                            } else if (
                                camelCaseClientWorkflowOptionProp ==
                                "valueChangeFields"
                            ) {
                                if (
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ]
                                ) {
                                    clientWorkflowOptions.valueChangeFields =
                                        clientSideEventTemplate[camelCaseProp][
                                            clientWorkflowOptionProp
                                        ];
                                }
                            }
                        }
                    }

                    delete clientSideEventTemplate[camelCaseProp];
                    clientSideEventTemplate.clientWorkflowOptions =
                        clientWorkflowOptions;
                } else if (camelCaseProp === "clientWorkFlowID") {
                    clientSideEventTemplate.clientWorkflowID =
                        clientSideEventTemplate[camelCaseProp];
                    delete clientSideEventTemplate[camelCaseProp];
                }
            }
        }

        Object.assign(newClientSideEventTemplate, clientSideEventTemplate);
        return newClientSideEventTemplate;
    }

    transformDataToSearchableDescendantListsTemplate(
        searchableDescendantListsTemplate: any
    ): SearchableDescendantListsTemplateDTO {
        const newSearchableDescendantListsTemplate =
            new SearchableDescendantListsTemplateDTO();
        for (const prop in searchableDescendantListsTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    searchableDescendantListsTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    searchableDescendantListsTemplate[camelCaseProp] =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                }

                if (camelCaseProp === "dataListID") {
                    newSearchableDescendantListsTemplate.datalistID =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "dataListLabel") {
                    newSearchableDescendantListsTemplate.datalistLabel =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "template") {
                    // This might be quite expensive. Need to check if this is necessary
                    // newSearchableDescendantListsTemplate.template =
                    //     transformResponseToDatalistDTO(
                    //         searchableDescendantListsTemplate[prop]
                    //     );
                    // delete searchableDescendantListsTemplate[prop];
                }
            }
        }

        Object.assign(
            newSearchableDescendantListsTemplate,
            searchableDescendantListsTemplate
        );
        return newSearchableDescendantListsTemplate;
    }

    iconCharacterMap = new Map([
        ["iconid", "id"],
        ["backgroundiconcolor", "backgroundColor"],
        ["iconcategory", "category"],
        ["iconcolor", "color"],
        ["iconname", "name"],
        ["upgraded", "upgraded"],
    ]);

    transformToIconTemplateDTO(optionTemplate: any): IconTemplateDTO {
        const iconCharacter = optionTemplate.IconCharacter;
        const newIconTemplate = new IconTemplateDTO();
        const parseIconCharacter = JSON.parse(iconCharacter);
        for (const prop in parseIconCharacter) {
            if (this.iconCharacterMap.has(prop.toLowerCase())) {
                const characterValue = this.iconCharacterMap.get(
                    prop.toLowerCase()
                );
                if (characterValue == "category") {
                    parseIconCharacter[characterValue] = decodeURIComponent(
                        parseIconCharacter[prop]
                    );
                } else if (characterValue) {
                    parseIconCharacter[characterValue] =
                        parseIconCharacter[prop];
                }
                delete parseIconCharacter[prop];
            }
        }
        Object.assign(newIconTemplate, parseIconCharacter);

        if (optionTemplate.image) {
            newIconTemplate.image = optionTemplate.image;
        }
        return newIconTemplate;
    }

    optionTemplateMap = new Map([
        ["datalisttype", "datalistType"],
        ["defaultviewtype", "defaultViewType"],
        ["maxnumberofcolumns", "maxNumberOfColumns"],
        ["topleveldisplay", "isTopLevelDisplay"],
        ["displaypreviousandnextbuttons", "diplayPreviousAndNextButtons"],
        [
            "autosavewithnextandpreviousbuttons",
            "autoSaveWithNextAndPreviousButtons",
        ],
        ["hideemptychildren", "hideEmptyChildren"],
        ["hidewhenreadonly", "hideWhenReadOnly"],
        ["preventinlineedit", "preventInlineEdit"],
        ["preventinlineadd", "preventInlineAdd"],
        ["combinedgridoptiontemplate", "combinedGridOptionTemplate"],
        ["iconcharacter", "iconCharacter"],
        ["cloneable", "cloneable"],
        ["disallowaddsfromtoplevel", "disallowAddsFromTopLevel"],
        [
            "requiresindividualmobilesubscription",
            "requiresIndividualMobileSubscription",
        ],
        ["enablesharing", "enableSharing"],
        ["enablesoundslikeonmobile", "enableSoundsLikeOnMobile"],
        ["asearchisrequiredtoviewrecords", "searchIsRequiredToViewRecords"],
        ["recordlabelformat", "recordLabelFormat"],
        ["recordheadercolumns", "recordHeaderColumns"],
    ]);
    transformDataToOptionTemplateDTO(optionTemplate: any): OptionTemplateDTO {
        const newOptionTemplate = new OptionTemplateDTO();
        // Option Template
        for (const prop in optionTemplate) {
            // Lowercase and remove hyphens
            const camelCaseProp = prop.toLowerCase().replace(/-/g, "");
            if (this.optionTemplateMap.has(camelCaseProp)) {
                const key = this.optionTemplateMap.get(camelCaseProp)!;
                const value = optionTemplate[prop];
                if (camelCaseProp === "combinedgridoptiontemplate") {
                    // This might be quite expensive. Need to check if this is necessary
                    const combinedGridOptionTemplate =
                        new CombinedGridOptionTemplateDTO();

                    for (const combinedGridProp in optionTemplate[prop]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                optionTemplate[prop],
                                combinedGridProp
                            )
                        ) {
                            const camelCaseCombinedGridProp =
                                combinedGridProp[0].toLowerCase() +
                                combinedGridProp.slice(1);
                            if (camelCaseCombinedGridProp != combinedGridProp) {
                                optionTemplate[prop][
                                    camelCaseCombinedGridProp
                                ] = optionTemplate[prop][combinedGridProp];
                                delete optionTemplate[prop][combinedGridProp];
                            }
                        }
                    }
                    //TODO: Check if combined grid templates are being created properly
                    Object.assign(
                        combinedGridOptionTemplate,
                        optionTemplate[prop]
                    );

                    optionTemplate[key] = combinedGridOptionTemplate;
                }
                // Icon Character
                else if (camelCaseProp === "iconcharacter") {
                    // image => iconCharacter
                    optionTemplate[key] =
                        this.transformToIconTemplateDTO(optionTemplate);
                }
                // Non boolean values
                else if (
                    camelCaseProp === "datalisttype" ||
                    camelCaseProp === "defaultviewtype" ||
                    camelCaseProp === "recordlabelformat"
                ) {
                    optionTemplate[key] = value;
                } else if (camelCaseProp === "maxnumberofcolumns") {
                    optionTemplate[key] = Number.parseInt(value);
                } else if (camelCaseProp === "recordheadercolumns" && value) {
                    optionTemplate[key] = value
                        .replace(/\s/g, "")
                        .split(",")
                        .map(Number);
                }
                // Boolean values
                else {
                    optionTemplate[key] =
                        BooleanUtilityMethods.convertToBoolean(value);
                }
            } else {
                this.logger.warn(
                    `Option template property ${camelCaseProp} not found`
                );
            }

            delete optionTemplate[prop];
        }
        // Assign back to template object
        // TODO: Separate transformation for option template especially combined grid
        Object.assign(newOptionTemplate, optionTemplate);

        return newOptionTemplate;
    }
    //#endregion
}
