import { HttpRequest, HttpHandlerFn, HttpEvent } from "@angular/common/http";
import { Observable, finalize } from "rxjs";
import { inject } from "@angular/core";
import { InProgressService } from "../services/in-progress.service";
import { environment } from "../../../../environments/environment";
import { InProgressProcess } from "../data/in-progress-process.model";

export function inProgressInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    const inProgressService = inject(InProgressService);
    const routes = environment.routes.mCase.routes;

    // Define specific URLs to track
    const trackedUrls = [
        routes.userDetailed.url,
        routes.updateAccountSettings.url,
        routes.updateOfflinePin.url,
        routes.updatePassword.url,
        routes.updateSecurityQuestion.url,
        routes.putRecordData.url,
        routes.postRecordData.url,
        routes.deleteRecordData.url,
        routes.getRecordData.url,
        routes.getTopLevelRecords.url,
    ];

    // Check if the request URL matches any tracked URL
    const shouldTrack = trackedUrls.some((url) => req.url.includes(url));

    let progress: InProgressProcess;

    if (shouldTrack) {
        // Start tracking process if URL matches
        progress = inProgressService.start();
        return next(req).pipe(
            finalize(() => {
                if (progress) {
                    // End tracking process
                    inProgressService.end(progress);
                }
            })
        );
    }
    return next(req);
}
