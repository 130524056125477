/**
 * Represents a Child Datalist Relationship Template DTO.
 */
export class DatalistRelationshipTemplateDTO {
    /**
     * The ID of the child list.
     */
    public childListID!: number;

    /**
     * The ID of the parent list.
     */
    public parentListID!: number;

    /**
     * Indicates whether to include merge.
     */
    public includeMerge: boolean = false;

    /**
     * Indicates whether to include searchable fields.
     */
    public includeSearchableFields: boolean = false;

    /**
     * Indicates whether to inherit readonly.
     */
    public inheritReadonly: boolean = false;

    /**
     * Indicates whether to inherit record owner
     */
    public inheritRecordOwner: boolean = false;
    /**
     * The order of the list.
     */
    public listOrder: number = 0;

    /**
     * The ID of the list relationship.
     */
    public listRelationshipID!: number;

    /**
     * The type of the relationship.
     * TEST if enums work during interception.
     */
    public type: string | null = "";

    /**
     * The conditions for the relationship.
     */
    public conditions: string[] = [];
}
