import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { JsonUserMapper } from "../../data/transformation/json-user.mapper";
import { UserDTO } from "../../data/model/user.dto";
import { IIndexable } from "../../../core/base/indexable.interface";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpMethod } from "../../../shared/enums/http-method.enum";

/**
 * Intercepts HTTP requests and responses which are related to user login and transforms the JSON objects to DTOs and vice versa.
 * @param request The request to be intercepted.
 * @param next The next interceptor in the chain.
 * @returns The observable of the HTTP event.
 */
export const userInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const mapper: JsonUserMapper = inject(JsonUserMapper);

    const allowedRoutes = [
        environment.routes.mCase.routes.login.url,
        environment.routes.mCase.routes.userDetailed.url,
        environment.routes.mCase.routes.updateAccountSettings.url,
        environment.routes.mCase.routes.getUsers.url,
    ];
    /**
     * Transforms the JSON object to a DTO object.
     * @param responseBody The JSON object to be transformed.
     * @returns The transformed DTO object.
     */
    const transformResponseToDTO = (responseBody: IIndexable): UserDTO => {
        return mapper.mapFrom(responseBody);
    };

    /**
     * Transforms the DTO object to a JSON object.
     * @param requestBody The DTO object to be transformed.
     * @returns The transformed JSON object.
     */
    const transformRequestToJson = (requestBody: UserDTO): IIndexable => {
        return mapper.mapTo(requestBody);
    };

    // Return if does not match our interceptor rule
    if (
        !(request instanceof HttpRequest) ||
        request.url === "" ||
        request.url == null ||
        !allowedRoutes.some((route) => request.url.includes(route))
    ) {
        return next(request);
    }

    // Transform request body from DTO to JSON
    if (request.method === HttpMethod.PUT) {
        request = request.clone({
            body: transformRequestToJson(request.body as UserDTO),
        });
        return next(request);
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule
            if (
                !(response instanceof HttpResponse) ||
                !response.url ||
                !(
                    environment.routes.mCase.routes.login.url ===
                        response.url ||
                    response.url.includes(
                        environment.routes.mCase.routes.userDetailed.url
                    ) ||
                    response.url.includes(
                        environment.routes.mCase.routes.getUsers.url
                    )
                )
            ) {
                return response;
            }

            // Transform response body from JSON to DTO
            if (response instanceof HttpResponse) {
                if (
                    request.url === environment.routes.mCase.routes.getUsers.url
                ) {
                    return response.clone({
                        body: (response.body as UserDTO[]).map((user) =>
                            transformResponseToDTO(user)
                        ),
                    });
                }

                return response.clone({
                    body: transformResponseToDTO(response.body as IIndexable),
                });
            }

            return response;
        })
    );
};
