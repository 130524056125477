import {
    HttpEvent,
    HttpHandlerFn,
    HttpHeaders,
    HttpInterceptorFn,
    HttpRequest,
} from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Observable, defer, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ILogger } from "../../../logging/models/logger.model";
import { LOGGER } from "../../../logging/providers/logger.provider";

// URLs that should not have the Authorization header added.
const WHITELISTED_URLS = Object.values(environment.routes.mCase.routes)
    .filter((route) => route.isWhiteListed)
    .map((route) => route.url);

/**
 * Adding bearer token to API calls
 */
export const authHeaderInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService: AuthService = inject(AuthService);
    const logger: ILogger = inject(LOGGER);

    // No Authorization header with Token needed for these URLs.
    if (WHITELISTED_URLS.some((x) => req.url.includes(x))) {
        logger.info("Whitelisted url, auth header not being added");
        return next(req);
    }

    const baseUrl = req.url.split("?")[0];
    logger.log(`Adding auth header to request url: ${baseUrl}`);
    // using defer to avoid eager execution of the Promise and
    // returning HttpHeaders with Authorization header added as response

    return defer(() => from(authService.getBearerToken())).pipe(
        switchMap((bearerToken) => {
            const reqWithAuth = req.clone({
                headers: req.headers
                    .set("Authorization", `Bearer ${bearerToken}`)
                    .set("RequestType", "1"),
            });

            return next(reqWithAuth);
        })
    );
};
