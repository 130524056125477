import { Injectable } from "@angular/core";
import { LoadConfig } from "../../loadProcess/config/load.config";
@Injectable({
    providedIn: "root",
})
export class InterceptorUtilityMethods {
    /**
     * Operations are case sensitive
     * @param inputScoreContainer object with properties score1Value, score2Value, ...  scoreNValue, where N is up to LoadConfig.numberOfSupportedScoreFields
     * @param outputScoreContainer
     */
    transformScores(inputScoreContainer: any, outputScoreContainer: any): void {
        for (
            let i: number = 0;
            i < LoadConfig.numberOfSupportedScoreFields;
            i++
        ) {
            let parsedScore = NaN;
            let scoreProp = `score${i + 1}value`;
            for (let prop in inputScoreContainer) {
                if (prop.toLowerCase() === scoreProp.toLowerCase()) {
                    parsedScore = Number.parseFloat(inputScoreContainer[prop]);
                }
            }
            outputScoreContainer[i] = isNaN(parsedScore) ? 0 : parsedScore;
        }
    }
}
