/**
 * Represents a conditionally mandatory field.
 */
export class ConditionallyMandatoryDTO {
    /**
     * The field that mandates the presence of this field.
     */
    mandatedByFieldId: number | null = null;

    /**
     * The value of the field that mandates the presence of this field.
     */
    mandatedByValue!: string;

    /**
     * Indicates whether this field is mandated only if the `mandatedByField` has a value.
     */
    mandatedIfFieldHasValue: boolean = false;
}
