import { inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { filter } from "rxjs/operators";
import { IUserMonitoring } from "../models/user-monitoring.model";
import { User } from "../../../login/core/domain/user.model";
import { environment } from "../../../../environments/environment";

/**
 * Service for monitoring user interactions and router events with Datadog.
 *
 * @remarks
 * This service implements the `IUserMonitoring` interface and provides methods
 * to track router events, set user information for monitoring, and stop monitoring.
 *
 */
export class DatadogUserMonitoringService implements IUserMonitoring {
    private _router = inject(Router);

    constructor() {
        this.initialize();
    }

    private initialize(): void {
        datadogRum.init({
            ...(environment.datadogRUMConfig as RumInitConfiguration),
            env: environment.environment,
        });
    }

    /**
     * Tracks the router events for Datadog.
     */
    trackRouter(): void {
        this._router.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd
                )
            )
            .subscribe((event: NavigationEnd) => {
                datadogRum.startView({
                    name: event.urlAfterRedirects,
                });
            });
    }

    /**
     * Sets the user for Datadog monitoring.
     * @param user User
     */
    setUser(user: User): void {
        // Check if the user monitoring configuration is set and the user is not already set.
        if (
            !environment.datadogRUMConfig.userTrackingConfig ||
            Object.keys(datadogRum.getUser()).length > 0
        ) {
            return;
        }

        if (environment.datadogRUMConfig.userTrackingConfig.trackUserId) {
            datadogRum.setUserProperty("id", user.id);
        }

        if (environment.datadogRUMConfig.userTrackingConfig.trackUserName) {
            datadogRum.setUserProperty("userName", user.username);
        }

        if (environment.datadogRUMConfig.userTrackingConfig.trackUserEmail) {
            datadogRum.setUserProperty("email", user.email);
        }
    }

    /**
     * Stops tracking for Datadog.
     */
    stopTracking(): void {
        datadogRum.stopSession();
    }
}
