import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, defer, from, map, switchMap } from "rxjs";
import { RecordInstanceDTO } from "../../../../record/data/models/recordInstance/recordInstance.dto";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { JsonRecordInstanceMapper } from "../../../../record/data/transformationMapper/json-record-instance.mapper";
import { IIndexable } from "../../../base/indexable.interface";
import { HttpMethod } from "../../../../shared/enums/http-method.enum";

/**
 * Intercepts HTTP requests and responses which are related to record and transforms the JSON objects to DTO
 * @param request The request to be intercepted.
 * @param next The next interceptor in the chain.
 * @returns The observable of the HTTP event.
 */

const allowedRoutes = [
    environment.routes.mCase.routes.getRecordData.url,
    environment.routes.mCase.routes.postRecordData.url,
    environment.routes.mCase.routes.putRecordData.url,
];

export const recordInstanceInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const jsonRecordInstanceMapper: JsonRecordInstanceMapper = inject(
        JsonRecordInstanceMapper
    );
    const transformRecordInstance = async (
        recordInstance: RecordInstanceDTO
    ): Promise<IIndexable> => {
        // Transform the record instance into a JSON object
        const response = await jsonRecordInstanceMapper.mapTo(recordInstance);
        return response;
    };

    /**
     * Transforms the JSON object to a DTO object.
     * @param responseBody The JSON object to be transformed.
     * @returns The transformed DTO object.
     */
    const transformResponseToDTO = (
        responseBody: IIndexable
    ): RecordInstanceDTO => {
        // TODO: Do we need to update the status back?
        return jsonRecordInstanceMapper.mapFrom(responseBody);
    };

    /**
     * Transforms the JSON object to an array of record IDs.
     * @param responseBody The JSON object to be transformed.
     * @returns The transformed array of record IDs
     */
    const transformResponseToIds = (responseBody: IIndexable[]): number[] => {
        return responseBody.map((recordData: IIndexable) => recordData["id"]);
    };

    // Return if does not match our interceptor rule
    if (
        !(request instanceof HttpRequest) ||
        !request.url ||
        !allowedRoutes.some((route) => request.url.includes(route))
    ) {
        return next(request);
    }

    if (
        request instanceof HttpRequest &&
        (request.method === HttpMethod.PUT ||
            request.method === HttpMethod.POST)
    ) {
        return defer(() =>
            from(transformRecordInstance(request.body as RecordInstanceDTO))
        ).pipe(
            switchMap((response: IIndexable) => {
                request = request.clone({
                    body: response,
                });
                return next(request).pipe(
                    map((response: HttpEvent<unknown>) => {
                        // Return if does not match our interceptor rule

                        if (
                            !(response instanceof HttpResponse) ||
                            !response.url
                        ) {
                            return response;
                        }

                        const responseUrl = response.url;
                        if (
                            !allowedRoutes.some((route) =>
                                responseUrl.includes(route)
                            )
                        ) {
                            return response;
                        }

                        // Transform response body from JSON to DTO
                        if (response instanceof HttpResponse) {
                            if (request.method === HttpMethod.DELETE) {
                                // Transform the response body to IDs if the request is a DELETE request
                                response = response.clone({
                                    body: transformResponseToIds(
                                        response.body as IIndexable[]
                                    ),
                                });
                            } else {
                                response = response.clone({
                                    body: transformResponseToDTO(
                                        response.body as IIndexable
                                    ),
                                });
                            }
                        }
                        return response;
                    })
                );
            })
        );
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule

            if (!(response instanceof HttpResponse) || !response.url) {
                return response;
            }

            const responseUrl = response.url;
            if (!allowedRoutes.some((route) => responseUrl.includes(route))) {
                return response;
            }

            // Transform response body from JSON to DTO
            if (response instanceof HttpResponse) {
                if (request.method === HttpMethod.DELETE) {
                    // Transform the response body to IDs if the request is a DELETE request
                    response = response.clone({
                        body: transformResponseToIds(
                            response.body as IIndexable[]
                        ),
                    });
                } else {
                    response = response.clone({
                        body: transformResponseToDTO(
                            response.body as IIndexable
                        ),
                    });
                }
            }
            return response;
        })
    );
};
