import { FieldTypes } from "./field-types.enum";

export const calculatedFieldSet = new Set<string>([
    FieldTypes.CalculatedField,
    FieldTypes.CalculatedNumber,
    FieldTypes.CalculatedMoney,
    FieldTypes.CalculatedDate,
    FieldTypes.CalculatedDateTime,
    FieldTypes.CalculatedTime,
    FieldTypes.CalculatedElapsedTime,
]);

export const dateSet = new Set<string>([
    FieldTypes.Date,
    FieldTypes.MirrorDate,
    FieldTypes.CalculatedDate,
]);
export const datetimeSet = new Set<string>([
    FieldTypes.DateTime,
    FieldTypes.MirrorDateTime,
    FieldTypes.CalculatedDateTime,
]);

export const moneySet = new Set<string>([
    FieldTypes.Money,
    FieldTypes.MirrorMoney,
    FieldTypes.CalculatedMoney,
]);

export const numberSet = new Set<string>([
    FieldTypes.Number,
    FieldTypes.MirrorNumber,
    FieldTypes.CalculatedNumber,
    FieldTypes.CalculatedAge,
    FieldTypes.CalculatedTime,
    FieldTypes.CalculatedElapsedTime,
    FieldTypes.Dropdown,
    FieldTypes.MirrorDropdown,
    FieldTypes.CascadingDropdown,
    FieldTypes.MirrorCascadingDropdown,
    FieldTypes.Score,
    FieldTypes.MaxScore,
    FieldTypes.MinScore,
    // Money is a subtype of number
    ...moneySet.values(),
]);
