import {
    Component,
    DestroyRef,
    inject,
    OnDestroy,
    signal,
    WritableSignal,
} from "@angular/core";
import { AuthService } from "./core/auth/infrastructure/services/auth.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NetworkStatusService } from "./shared/infrastructure/services/network-status.service";
import { LOGGER } from "./core/logging/providers/logger.provider";
import { LoadSystemSettingsUsecase } from "./core/system-settings/core/usecases/load-system-settings.usecase";
import { MatIconRegistry } from "@angular/material/icon";
import { MainSettings } from "./main/config/main.config";
import { LOGIN_STATUS } from "./core/data/enum/login-status.enum";
import { LoginInfo } from "./core/data/model/login-info";
import { USER_MONITORING } from "./core/user-monitoring/factories/user-monitoring.factory";
import { IUserMonitoring } from "./core/user-monitoring/models/user-monitoring.model";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
    standalone: false,
})
export class AppComponent implements OnDestroy {
    private authService: AuthService = inject(AuthService);
    private router: Router = inject(Router);
    private _snackBar = inject(MatSnackBar);
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);
    private logger = inject(LOGGER);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private loadSystemSettingsUsecase: LoadSystemSettingsUsecase = inject(
        LoadSystemSettingsUsecase
    );
    private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
    private userMonitoring: IUserMonitoring | null = inject(USER_MONITORING);
    isLoading: WritableSignal<boolean> = signal(true);
    isSystemSettingsLoaded: WritableSignal<boolean> = signal(false);
    readonly mainSettings = MainSettings;

    constructor() {
        this.userMonitoring?.trackRouter();

        this.userLoggedInSubscription();
        this.loadSystemSettings();
    }

    /**
     * Load system settings
     */
    private async loadSystemSettings(): Promise<void> {
        try {
            this.logger.debug("Loading system settings");
            await this.loadSystemSettingsUsecase.execute();

            this.isSystemSettingsLoaded.set(true);
        } catch (error) {
            this.logger.error("Error while loading system settings", error);

            this._snackBar.open(
                "An error occurred while configuring the system settings.",
                "Close"
            );

            this.isSystemSettingsLoaded.set(false);
        } finally {
            this.isLoading.set(false);
        }
    }

    ngOnInit(): void {
        this.matIconRegistry.setDefaultFontSetClass(
            "material-symbols-outlined"
        );
    }

    ngOnDestroy(): void {
        this.networkStatusService.onDestroy();
    }

    /**
     * Subscription for isUserLoggedIn
     */
    private userLoggedInSubscription(): void {
        this.authService.loginInfo$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((loginInfo: LoginInfo) => {
                switch (loginInfo.loginStatus) {
                    case LOGIN_STATUS.USER_LOGGED_IN:
                        this.userMonitoring?.setUser(
                            this.authService.getCurrentUser()
                        );
                        this.router.navigate(["/home"]);
                        break;

                    case LOGIN_STATUS.PASSWORD_RESET_REQUIRED:
                        this.userMonitoring?.setUser(
                            this.authService.getCurrentUser()
                        );
                        this.router.navigate(["/login/password-reset"], {
                            state: { currentPassword: loginInfo.password },
                        });
                        break;

                    case LOGIN_STATUS.USER_LOGGED_OUT:
                        this.userMonitoring?.stopTracking();
                        break;
                }
            });
    }
}
