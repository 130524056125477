@if (isLoading()) {
    <mcase-loading-spinner />
} @else if (isSystemSettingsLoaded()) {
    <router-outlet />
} @else {
    <div class="p-6 text-center">
        <h6 class="mat-headline-small">
            {{ mainSettings.genericFailureMessage }}
        </h6>
    </div>
}
