import { FieldTemplateDTO } from "../../../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { DatalistTemplate } from "../../../../../record/core/domain/datalistTemplate/datalist-template";
import { DatalistTemplateDTO } from "../../../../../record/data/models/datalist/datalist-template.dto";
import { FieldTypes } from "../../../../domain/enums/field-types.enum";

export class Node<T extends DatalistTemplateDTO | DatalistTemplate> {
    fieldMap: Map<string, FieldTemplateDTO> = new Map<
        string,
        FieldTemplateDTO
    >();
    fieldMapByFieldId: Map<number, FieldTemplateDTO> = new Map<
        number,
        FieldTemplateDTO
    >();
    dynamicCalculatedFields: Set<FieldTemplateDTO> =
        new Set<FieldTemplateDTO>();
    dynamicFields: Set<string> = new Set<string>();
    embeddedDocumentFields: Set<number> = new Set<number>();
    calculatedFields: Set<number> = new Set<number>();
    userFields: Set<number> = new Set<number>();
    mirrorFields: Set<string> = new Set<string>();
    dateTimeFields: Set<number> = new Set<number>();
    dateFields: Set<number> = new Set<number>();
    data: T;
    neighbors: Map<number, Node<T>> = new Map<number, Node<T>>();
    // oppositeDependencies: Set<number> = new Set<number>();
    parentDependencies: Set<Node<T>> = new Set<Node<T>>();
    constructor(data: T) {
        this.data = data;
    }

    addData(data: T) {
        this.data = data;
    }

    addNeighbor(neighbor: Node<T>) {
        // add neighbor to the node
        this.neighbors.set(neighbor.data.datalistID, neighbor);
    }

    addToFieldMap(fieldTemplate: FieldTemplateDTO) {
        this.fieldMapByFieldId.set(fieldTemplate.fieldID!, fieldTemplate);

        if (this.fieldMap.has(fieldTemplate.systemName)) {
            // if the fieldID already exists, update the field type and field system name
            this.fieldMap.set(fieldTemplate.systemName, fieldTemplate);
            return;
        }

        switch (fieldTemplate.type) {
            case FieldTypes.DynamicDropdown:
            case FieldTypes.CascadingDynamicDropdown:
                // if the field type is dynamic, add the field to the dynamic fields set
                this.dynamicFields.add(fieldTemplate.systemName!);
                break;
            case FieldTypes.DynamicCalculatedField:
                this.dynamicCalculatedFields.add(fieldTemplate);
                break;
            case FieldTypes.User:
                // if the field type is user, add the field to the user fields set
                this.userFields.add(fieldTemplate.fieldID!);
                break;
            case FieldTypes.Date:
                // if the field type is date, add the field to the date fields set
                this.dateFields.add(fieldTemplate.fieldID!);
                break;
            case FieldTypes.DateTime:
                // if the field type is date time, add the field to the date time fields set
                this.dateTimeFields.add(fieldTemplate.fieldID!);
                break;
            case FieldTypes.CalculatedField:
                this.calculatedFields.add(fieldTemplate.fieldID!);
                break;
            case FieldTypes.EmbeddedDocument:
                this.embeddedDocumentFields.add(fieldTemplate.fieldID!);
                break;
            default:
                break;
        }

        this.fieldMap.set(fieldTemplate.systemName, fieldTemplate);
    }

    addParentDependency(parent: Node<T>) {
        // add the source node to the opposite dependencies
        this.parentDependencies.add(parent);
    }
}
