import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ClientSideEventTemplateDTO } from "../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { ClientWorkflowOptionsDTO } from "../../../record/data/models/datalist/clientEvents/client-workflow-options.dto";
import { CombinedGridOptionTemplateDTO } from "../../../record/data/models/datalist/combined-grid-option-template.dto";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { LabelTemplateDTO } from "../../../record/data/models/datalist/label-template.dto";
import { OptionTemplateDTO } from "../../../record/data/models/datalist/option-template.dto";
import { SearchableDescendantListsTemplateDTO } from "../../../record/data/models/datalist/searchable-descendant-lists-template.dto";
import { HeaderFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/header-field-template.dto";
import { SectionFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/section-field-template.dto";
import { FieldValidatorDTO } from "../../data/model/fieldValidator/fieldValidator.dto";
import { FieldDependencyOptionDTO } from "../../data/model/fieldDependencyOption/field-dependency-option.dto";
import { FieldDependencyDTO } from "../../data/model/fieldDependencyOption/field-dependency.dto";
import { ComparisonOperator } from "../../domain/enums/comparison-operator.enum";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { FieldValidators } from "../../domain/enums/field-validators.enum";
import { ConditionallyMandatoryDTO } from "../../../record/data/models/options/conditionally-mandatory.dto";
import { CascadingDropdownFieldValue } from "../../domain/dropdownValues/cascading-drop-down-field-value.model";
import { DropdownFieldValue } from "../../domain/dropdownValues/drop-down-field-value.model";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { SortDirection } from "../../domain/enums/sort-direction.enum";
import { MirrorDependency } from "../../../record/core/domain/mirroring/mirror-dependency";
import { Graph } from "../../loadProcess/data/model/templateGraph/graph";
import { Node } from "../../loadProcess/data/model/templateGraph/node";
import { MirrorOptionType } from "../../domain/enums/mirror-option-type.enum";
import { DynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-dropdown-field-template.dto";
import { UserMirrorDependencyOption } from "../../../record/core/domain/mirroring/user-mirror-dependency-option";
import { DynamicMirrorDependencyOption } from "../../../record/core/domain/mirroring/dynamic-mirror-dependency-option";
import { RegularMirrorDependencyOption } from "../../../record/core/domain/mirroring/regular-mirror-dependency-option";
import { ParentMirrorDependencyOption } from "../../../record/core/domain/mirroring/parent-mirror-dependency-option";
import { LOGGER } from "../../logging/providers/logger.provider";
import { MirrorFormulaConstants } from "../../domain/enums/mirror-formula-constants.enum";
import { SoundsLikeMode } from "../../domain/enums/sounds-like-mode.enum";
import {
    MirrorFieldMap,
    MirrorFieldTemplateDTOArray,
} from "../../domain/enums/mirror-types";
import { IconTemplateDTO } from "../../../record/data/models/datalist/icon-template.dto";
import { InterceptorUtilityMethods } from "../utility/interceptor-utility-methods";
import { BooleanUtilityMethods } from "../../../shared/utilities/type-extensions/boolean-utility-methods.service";
import { ServerTemplateParseService } from "../../loadProcess/services/server-parse-template.service";
import { DatalistRelationshipTemplateDTO } from "../../../record/data/models/datalist/childDatalistRelationship/datalist-relationship-template.dto";
//TODO: clean-up #487
//TODO: Switch to promise based interceptor if needed.
export const templateInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const logger = inject(LOGGER);
    const interceptorUtilityMethods = inject(InterceptorUtilityMethods);
    const serverTemplateParseService = inject(ServerTemplateParseService);

    const mCaseRoutes = environment.routes.mCase.routes;
    const approvedRoute = mCaseRoutes.getTemplates.url;
    //#region Field Type transformation + mirror transform
    const isDefaultValueMirror = (fieldTemplate: any): boolean => {
        if (
            Object.prototype.hasOwnProperty.call(
                fieldTemplate,
                "defaultValue"
            ) &&
            fieldTemplate.defaultValue != null
        ) {
            const defaultValue: string = fieldTemplate.defaultValue;
            if (defaultValue.startsWith("{[") && defaultValue.endsWith("]}")) {
                return true;
            }
        }

        return false;
    };

    // TODO: Export
    const fieldMap = new Map([
        ["address", FieldTypes.Address],
        ["attachment", FieldTypes.Attachment],
        ["boolean", FieldTypes.Boolean],
        ["calculatedfield", FieldTypes.CalculatedField],
        ["cascadingdropdown", FieldTypes.CascadingDropdown],
        ["cascadingdynamicdropdown", FieldTypes.CascadingDynamicDropdown],
        ["checkbox", FieldTypes.Checkbox],
        ["date", FieldTypes.Date],
        ["datetime", FieldTypes.DateTime],
        ["dropdown", FieldTypes.Dropdown],
        ["dropdownlist", FieldTypes.Dropdown],
        ["dynamicdropdown", FieldTypes.DynamicDropdown],
        ["dynamiccalculatedfield", FieldTypes.DynamicCalculatedField],
        ["emailaddress", FieldTypes.EmailAddress],
        ["embeddeddocument", FieldTypes.EmbeddedDocument],
        ["embeddedlist", FieldTypes.EmbeddedList],
        ["externalobject", FieldTypes.ExternalObject],
        ["linebreak", FieldTypes.LineBreak],
        ["longstring", FieldTypes.LongString],
        ["maxscore", FieldTypes.MaxScore],
        ["minscore", FieldTypes.MinScore],
        ["money", FieldTypes.Money],
        ["narrative", FieldTypes.Narrative],
        ["number", FieldTypes.Number],
        ["phone", FieldTypes.Phone],
        ["score", FieldTypes.Score],
        ["readonlyfield", FieldTypes.ReadOnly],
        ["string", FieldTypes.String],
        ["time", FieldTypes.Time],
        ["uniqueidentifier", FieldTypes.UniqueIdentifier],
        ["url", FieldTypes.Url],
        ["user", FieldTypes.User],
        ["userrolesecurityrestrict", FieldTypes.Urbs],
        ["userroles", FieldTypes.UserRoles],
    ]);
    // Most likely move to field utilities
    const transformFieldType = (fieldTemplate: any): string => {
        let fieldType = fieldTemplate.addType;
        // Remove spaces
        fieldType = fieldType.replace(/\s/g, "");
        fieldType = fieldType.replace(/-/g, "");

        if (fieldType.toLowerCase().startsWith("score")) {
            fieldType = FieldTypes.Score;
            updateScoreFieldTemplate(fieldTemplate);
        } else if (fieldMap.has(fieldType.toLowerCase())) {
            fieldType = fieldMap.get(fieldType.toLowerCase());
        } else {
            throw `Field type ${fieldType} not found`;
        }

        if (isDefaultValueMirror(fieldTemplate)) {
            // Update type to mirror type
            fieldType = MirrorFieldMap.get(fieldType);
        }
        return fieldType;
    };
    //#endregion

    const updateScoreFieldTemplate = (fieldTemplate: any): void => {
        const scoreMatch = fieldTemplate.addType.match(/\d+/);
        if (scoreMatch != null) {
            fieldTemplate.scoreIdentifier = parseInt(scoreMatch[0]);
        }
    };

    //#region Transform Response to DTO
    const transformResponseToDatalistDTO = (
        responseBody: HttpEvent<any>,
        graph: Graph<DatalistTemplateDTO>
    ): any => {
        // Create a new Datalist DTO object
        const template: DatalistTemplateDTO = new DatalistTemplateDTO();

        // Clone response body
        const responseBodyCopy = { ...responseBody };

        const templateListID = Object.entries(responseBodyCopy).find(
            ([key, value]) => {
                return key === "listId";
            }
        );

        if (!templateListID) {
            throw new Error(`List ID not found in response body`);
        }

        template.datalistID = templateListID[1];

        // Go over each property in the response body
        Object.entries(responseBodyCopy).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                return;
            }

            if (key === "optionTemplate") {
                template.optionTemplate =
                    transformDataToOptionTemplateDTO(value);
            } else if (key === "labelTemplate") {
                // Label Template
                template.labelTemplate = Object.assign(
                    new LabelTemplateDTO(),
                    value
                );
            } else if (key === "children") {
                // Children
                template.children = value.map((child: any) => {
                    for (const prop in child) {
                        if (Object.prototype.hasOwnProperty.call(child, prop)) {
                            const camelCaseProp =
                                prop[0].toLowerCase() + prop.slice(1);
                            if (camelCaseProp != prop) {
                                child[camelCaseProp] = child[prop];
                                delete child[prop];
                            }
                        }
                    }
                    const childDatalistRelationshipTemplateDTO = Object.assign(
                        new DatalistRelationshipTemplateDTO(),
                        child
                    );
                    // graph.addEdge(graph.getNodeByID(template.datalistID), graph.getNodeByID(childDatalistRelationshipTemplateDTO.childListID));
                    // graph.getNodeByID
                    return childDatalistRelationshipTemplateDTO;
                });
            } else if (key === "listLabel") {
                template.name = value;
            } else if (key === "dataListDescription") {
                template.description = value;
            } else if (key === "listId") {
                template.datalistID = value;
            } else if (key === "sortOrder") {
                template.sortOrder = value;
            } else if (key === "readOnlyChildren") {
                template.readOnlyChildren = value;
            } else if (key === "checksum") {
                template.checksum = value;
            } else if (key === "allowAdd") {
                template.allowAdd =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowModify") {
                template.allowModify =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowDelete") {
                template.allowDelete =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowMove") {
                template.allowMove =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowActivityWall") {
                template.allowActivityWall =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowMerge") {
                template.allowMerge =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowEditOthers") {
                template.allowEditOthers =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "allowEditOthersDraft") {
                template.allowEditOthersDraft =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "isListAdmin") {
                template.isListAdmin =
                    BooleanUtilityMethods.convertToBoolean(value);
            } else if (key === "isInfrastructureList") {
                template.isInfrastructureList =
                    BooleanUtilityMethods.convertToBoolean(value);
            }
            // else if (key === "elementAccessEvaluated") {
            //     template.elementAccessEvaluated = value;
            // }
            else if (key === "adminInfo") {
                // TODO: Separate transformation might be needed
                template.adminInfo = value;
            } else if (key === "systemUsers") {
                template.systemUsers = value;
            } else if (key === "clientSideEventTemplates") {
                // TODO: Separate transformation might be needed
                const clientSideEventTemplates: ClientSideEventTemplateDTO[] =
                    value.map((clientSideEventTemplate: any) => {
                        return transformDataToClientSideEventTemplate(
                            clientSideEventTemplate
                        );
                    });

                template.clientSideEventTemplates = clientSideEventTemplates;
            } else if (key === "searchableDescendantLists") {
                // TODO: Separate transformation might be needed
                const searchableDescendantLists: SearchableDescendantListsTemplateDTO[] =
                    value.map((searchableDescendantList: any) => {
                        return transformDataToSearchableDescendantListsTemplate(
                            searchableDescendantList
                        );
                    });

                template.searchableDescendantLists =
                    searchableDescendantLists as SearchableDescendantListsTemplateDTO[];
            } else if (key === "systemName") {
                template.systemName = value;
            }
        });

        graph.addNode(new Node<DatalistTemplateDTO>(template));
        /**
         * We go over sections and fields separately and after option template and label template
         * have been resolved. This is because we need to determine show in header fields.
         */
        const sectionsKV = Object.entries(responseBodyCopy).find(
            ([key, value]) => {
                return key === "sections";
            }
        );

        if (!sectionsKV) {
            throw new Error(`Sections not found in response body`);
        }

        const key = sectionsKV[0];
        const value = sectionsKV[1];

        if (key && value) {
            // Create our section => header => field template structure
            template.sections = value.map((section: any) => {
                // Go over each property in a section
                for (const prop in section) {
                    // Camel case conversion
                    if (Object.prototype.hasOwnProperty.call(section, prop)) {
                        const camelCaseProp =
                            prop[0].toLowerCase() + prop.slice(1);
                        if (camelCaseProp != prop) {
                            section[camelCaseProp] = section[prop];
                            delete section[prop];
                        }
                        // Create headerFieldTemplateDTO
                        if (camelCaseProp === "headers") {
                            const headers: HeaderFieldTemplateDTO[] = [];
                            section[camelCaseProp].map((header: any) => {
                                for (const headerProp in header) {
                                    if (
                                        Object.prototype.hasOwnProperty.call(
                                            header,
                                            headerProp
                                        )
                                    ) {
                                        const camelCaseHeaderProp =
                                            headerProp[0].toLowerCase() +
                                            headerProp.slice(1);
                                        if (camelCaseHeaderProp != headerProp) {
                                            header[camelCaseHeaderProp] =
                                                header[headerProp];
                                            delete header[headerProp];
                                        }
                                        // Go over fieldTemplates
                                        if (
                                            camelCaseHeaderProp ===
                                            "fieldTemplates"
                                        ) {
                                            let fieldTemplates =
                                                header[camelCaseHeaderProp];

                                            // Filter out some fields. TODO: Create a new list of field types to filter out
                                            const filteredTemplates =
                                                fieldTemplates.filter(
                                                    (ft: any) =>
                                                        ft.addType !=
                                                            "OLFSAttachment" &&
                                                        ft.addType !=
                                                            "Intake Referral ID" &&
                                                        ft.addType !=
                                                            "Current Date" &&
                                                        ft.addType !=
                                                            "Current User" &&
                                                        ft.addType !=
                                                            "Hidden Field"
                                                );

                                            fieldTemplates =
                                                filteredTemplates.map(
                                                    (fieldTemplate: any) => {
                                                        fieldTemplate.datalistID =
                                                            template.datalistID;
                                                        const transformedFieldTemplate =
                                                            transformDataToFieldTemplateDTO(
                                                                fieldTemplate,
                                                                graph
                                                            );
                                                        graph
                                                            .getNodeByID(
                                                                template.datalistID
                                                            )
                                                            ?.addToFieldMap(
                                                                transformedFieldTemplate
                                                            );
                                                        return transformedFieldTemplate;
                                                    }
                                                );

                                            // Confirm if the type is correct
                                            header.children = fieldTemplates;
                                            delete header.fieldTemplates;
                                        }
                                    }
                                }
                                header.fieldID = header.fieldId; // This could be null. To be determined what to do with this.
                                delete header.fieldId;
                                const newHeader: HeaderFieldTemplateDTO =
                                    Object.assign(
                                        new HeaderFieldTemplateDTO(),
                                        header
                                    );
                                headers.push(newHeader);
                            });

                            delete section.headers;
                            section.children = headers;
                        }
                    }
                }

                if (section.fieldId == null) {
                    // section.fieldID = Math.floor(Math.random()) // TODO: Something more specific?
                    section.fieldID = null;
                } else {
                    section.fieldID = section.fieldId;
                }
                // Remove older reference
                delete section.fieldId;
                return Object.assign(new SectionFieldTemplateDTO(), section);
            });
        } else {
            throw new Error(`Sections not found in response body`);
        }
        return template;
    };

    const generateMirrorDependencyForFieldTemplate = (
        fieldTemplate: FieldTemplateDTO,
        graph: Graph<DatalistTemplateDTO>
    ): void => {
        const fieldDefaultValue = fieldTemplate["defaultValue"];

        if (fieldDefaultValue == null || fieldDefaultValue === "") {
            return;
        }

        // Check if the default value indicates mirroring.
        const matches = fieldDefaultValue.match(
            /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
        );
        // No mirror matches found
        if (matches == null) {
            return;
        }
        // Initialize mirror dependency object
        const mirrorDependency = new MirrorDependency();
        const numberOfMatches = matches.length;
        // Mirror formula
        mirrorDependency.formula = fieldDefaultValue;
        if (numberOfMatches > 1) {
            mirrorDependency.formula = mirrorDependency.formula.substring(
                2,
                mirrorDependency.formula.length - 2
            );
            fieldTemplate.type = FieldTypes.MirrorString;
        }

        // Go over matches
        matches?.forEach((innerMatch: string) => {
            const matchParts = innerMatch.match(
                /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/
            );

            if (matchParts?.groups?.["reference"] != null) {
                const referencedDatalist = graph.getNodeByID(
                    fieldTemplate.datalistID
                );

                if (referencedDatalist == null) {
                    logger.error(
                        `Datalist ${fieldTemplate.datalistID} not found`
                    );
                    return;
                }

                // Reference can be DDD field or user
                if (
                    matchParts.groups?.["reference"] !=
                    MirrorFormulaConstants.parent
                ) {
                    const referencedFieldOnTemplate =
                        referencedDatalist.fieldMap.get(
                            matchParts.groups?.["reference"]
                        );

                    if (referencedFieldOnTemplate == null) {
                        logger.error(
                            `Field ${matchParts.groups?.["reference"]} not found on datalist ${fieldTemplate.datalistID}`
                        );
                        throw new Error(
                            `Field ${matchParts.groups?.["reference"]} not found on datalist ${fieldTemplate.datalistID}`
                        );
                    }

                    // The source field is dynamic dropdown.
                    if (
                        referencedFieldOnTemplate.type ===
                        FieldTypes.DynamicDropdown
                    ) {
                        // TODO: Check if CDDD etc
                        logger.log(
                            `Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is a DDD field`
                        );
                        // Field found. Add to dependency
                        const dynamicDTO: DynamicDropdownFieldTemplateDTO =
                            referencedFieldOnTemplate as DynamicDropdownFieldTemplateDTO;

                        if (
                            dynamicDTO.source &&
                            graph.getNodeByID(dynamicDTO.source) != null
                        ) {
                            const parent = graph.getNodeByID(dynamicDTO.source);
                            const dynamicReferenceFieldTemplate =
                                parent.fieldMap.get(
                                    matchParts.groups?.["systemName"]
                                );
                            if (!dynamicReferenceFieldTemplate) {
                                throw new Error(
                                    `Field ${matchParts.groups?.["systemName"]} not found on datalist ${parent.data.datalistID}`
                                );
                            }
                            // Add type check
                            const mirrorDependencyField =
                                new DynamicMirrorDependencyOption();
                            mirrorDependencyField.fieldName =
                                referencedFieldOnTemplate.systemName;
                            mirrorDependencyField.fieldID =
                                referencedFieldOnTemplate.fieldID!;
                            mirrorDependencyField.mirrorType =
                                MirrorOptionType.Dynamic;
                            mirrorDependencyField.dynamicDatalistID =
                                parent.data.datalistID;
                            mirrorDependencyField.dynamicDatalistSystemName =
                                parent.data.systemName;
                            mirrorDependencyField.dynamicDatalistFieldID =
                                dynamicReferenceFieldTemplate.fieldID!;
                            mirrorDependencyField.dynamicDatalistFieldName =
                                dynamicReferenceFieldTemplate.systemName;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    }
                    // The source field is a user field
                    else if (
                        referencedFieldOnTemplate.type === FieldTypes.User
                    ) {
                        logger.log(
                            `Field ${matchParts.groups?.["reference"]} found on datalist ${fieldTemplate.datalistID} and is an user field`
                        );
                        const mirrorDependencyField =
                            new UserMirrorDependencyOption();
                        mirrorDependencyField.fieldName =
                            referencedFieldOnTemplate.systemName;
                        mirrorDependencyField.fieldID =
                            referencedFieldOnTemplate.fieldID!;
                        mirrorDependencyField.mirrorType =
                            MirrorOptionType.User;
                        mirrorDependencyField.userPropertyName =
                            matchParts.groups?.["systemName"]; // TODO: UserData Object
                        mirrorDependencyField.mirrorLabel = innerMatch;
                        mirrorDependency.fields.push(mirrorDependencyField);
                    }
                }
                // Parent reference
                else if (
                    matchParts?.groups?.["reference"].toLowerCase() ===
                    MirrorFormulaConstants.parent
                ) {
                    const referenceSystemName =
                        matchParts?.groups?.["systemName"];
                    // Go over each parent dependency
                    referencedDatalist?.parentDependencies.forEach(
                        (parentNode: Node<DatalistTemplateDTO>) => {
                            if (
                                matchParts?.groups &&
                                !parentNode.fieldMap.has(referenceSystemName)
                            ) {
                                logger.error(
                                    `Field ${matchParts?.groups?.["systemName"]} not found on datalist ${parentNode.data.datalistID}`
                                );
                                throw new Error(
                                    `Field ${matchParts?.groups?.["systemName"]} not found on datalist ${parentNode.data.datalistID}`
                                );
                            }
                            const mirrorDependencyField =
                                new ParentMirrorDependencyOption();
                            mirrorDependencyField.parentDatalistFieldSystemName =
                                referenceSystemName; // Mirror source field name
                            mirrorDependencyField.parentDatalistID =
                                parentNode.data.datalistID;
                            mirrorDependencyField.parentDatalistSystemName =
                                parentNode.data.systemName;
                            mirrorDependencyField.parentDatalistFieldID =
                                parentNode.fieldMap.get(
                                    referenceSystemName
                                )!.fieldID!;
                            mirrorDependencyField.mirrorLabel = innerMatch;
                            mirrorDependency.fields.push(mirrorDependencyField);
                        }
                    );
                }
            }
            // Reference not found. Simple mirror
            else if (matchParts?.groups?.["systemName"] != null) {
                const mirrorDependencyField =
                    new RegularMirrorDependencyOption();
                mirrorDependencyField.fieldName =
                    matchParts?.groups?.["systemName"];
                // TODO: Clean this up
                mirrorDependencyField.fieldID = graph
                    .getNodeByID(fieldTemplate.datalistID)
                    .fieldMap.get(matchParts?.groups?.["systemName"])!.fieldID!;
                mirrorDependencyField.mirrorType = MirrorOptionType.Regular;
                mirrorDependencyField.mirrorLabel = innerMatch;
                mirrorDependency.fields.push(mirrorDependencyField);
            } else {
                // TODO: COAELSCE
            }
        });
        (fieldTemplate as MirrorFieldTemplateDTOArray).valueDependency =
            mirrorDependency;
    };

    /**
     * Transforms the given field template object into a FieldTemplateDTO object.
     *
     * @param fieldTemplate - The field template object to transform.
     * @returns The transformed FieldTemplateDTO object.
     */
    const transformDataToFieldTemplateDTO = (
        fieldTemplate: any,
        graph: Graph<DatalistTemplateDTO>
    ): FieldTemplateDTO => {
        // Initialize field dependency object. This will be used to store the field dependency information.
        const fieldDependency = new FieldDependencyDTO();
        fieldTemplate.fieldID = fieldTemplate.fieldId;
        // Go over each property in the field template object
        for (const fieldProp in fieldTemplate) {
            // Redundant check
            if (
                Object.prototype.hasOwnProperty.call(fieldTemplate, fieldProp)
            ) {
                // Camel case conversion
                const camelCaseFieldProp =
                    fieldProp[0].toLowerCase() + fieldProp.slice(1);
                //#region Field Dependencies
                if (camelCaseFieldProp === "allDependenciesMustPass") {
                    fieldDependency.allDependenciesMustPass =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "displayOption" ||
                    camelCaseFieldProp === "Displayoption"
                ) {
                    // enum conversion
                    fieldDependency.displayOption = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "items") {
                    // Transform per field type
                    transformItemsToFieldTemplateDTOOptions(fieldTemplate);
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "dependencies") {
                    // List of dependencies
                    const dependencies: FieldDependencyOptionDTO[] = [];
                    // Go over each dependency
                    fieldTemplate[fieldProp]?.map((dependency: any) => {
                        const fieldDependencyOption =
                            new FieldDependencyOptionDTO();
                        // Go over each dependency property
                        for (const dependencyProp in dependency) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    dependency,
                                    dependencyProp
                                )
                            ) {
                                const camelCaseDependencyProp =
                                    dependencyProp[0].toLowerCase() +
                                    dependencyProp.slice(1);
                                if (
                                    camelCaseDependencyProp ===
                                    "dependentFieldID"
                                ) {
                                    // enum conversion
                                    fieldDependencyOption.dependentFieldID =
                                        dependency[dependencyProp];
                                } else if (
                                    camelCaseDependencyProp ===
                                    "dependentOnFieldID"
                                ) {
                                    fieldDependencyOption.dependentOnFieldID =
                                        dependency[dependencyProp];
                                } else if (
                                    camelCaseDependencyProp ===
                                    "fieldDependencyID"
                                ) {
                                    fieldDependencyOption.fieldDependencyID =
                                        dependency[dependencyProp];
                                } else if (
                                    camelCaseDependencyProp === "value"
                                ) {
                                    fieldDependencyOption.value =
                                        dependency[dependencyProp];
                                } else if (camelCaseDependencyProp === "type") {
                                    // Will this throw an error if not found? This should an exception?
                                    fieldDependencyOption.type =
                                        ComparisonOperator[
                                            dependency[
                                                dependencyProp
                                            ] as keyof typeof ComparisonOperator
                                        ];
                                }

                                if (camelCaseDependencyProp != dependencyProp) {
                                    dependency[camelCaseDependencyProp] =
                                        dependency[dependencyProp];
                                    delete dependency[dependencyProp];
                                }
                            }
                        }

                        dependencies.push(fieldDependencyOption);
                    });
                    fieldDependency.dependencies = dependencies;
                }
                //#endregion
                //#region Validations
                else if (camelCaseFieldProp === "validations") {
                    const validators: FieldValidatorDTO[] = fieldTemplate[
                        fieldProp
                    ].map((validator: any) => {
                        const fieldValidator = new FieldValidatorDTO();
                        for (const validatorProp in validator) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    validator,
                                    validatorProp
                                )
                            ) {
                                const camelCaseValidatorProp =
                                    validatorProp[0].toLowerCase() +
                                    validatorProp.slice(1);
                                if (
                                    camelCaseValidatorProp ===
                                    "regularExpression"
                                ) {
                                    fieldValidator.validationValue =
                                        validator[validatorProp];
                                } else if (
                                    camelCaseValidatorProp === "errorMessage"
                                ) {
                                    fieldValidator.validationMessage =
                                        validator[validatorProp];
                                    if (
                                        validator[validatorProp].includes(
                                            "Exceeds"
                                        )
                                    ) {
                                        // Long string max. Need some better way to determine this
                                        fieldValidator.validationType =
                                            FieldValidators.LongStringMax;
                                    } else {
                                        fieldValidator.validationType =
                                            FieldValidators.Regex;
                                    }
                                }
                                if (camelCaseValidatorProp != validatorProp) {
                                    validator[camelCaseValidatorProp] =
                                        validator[validatorProp];
                                    delete validator[validatorProp];
                                }
                            }
                        }
                        return fieldValidator;
                    });

                    fieldTemplate.validators = validators;

                    if (fieldTemplate.required) {
                        const requiredValidator = new FieldValidatorDTO();
                        requiredValidator.validationType =
                            FieldValidators.Required;
                        fieldTemplate.validators.push(requiredValidator);
                    }

                    delete fieldTemplate[fieldProp];
                }
                //#endregion
                //#region Field Options
                else if (camelCaseFieldProp === "fieldOptions") {
                    const conditionallyMandatory =
                        new ConditionallyMandatoryDTO();
                    // Field Options
                    fieldTemplate[fieldProp].forEach((fieldOption: any) => {
                        const optionName = fieldOption["Key"];
                        const optionValue = fieldOption["Value"];
                        // Update option name to remove spaces and case correct.
                        const modifiedOptionName = optionName
                            .split(" ")
                            .map((word: string, index: number) => {
                                // Lowercase the first word
                                if (index === 0) {
                                    return word.toLowerCase();
                                }
                                // Capitalize the first letter of the rest of the words
                                else {
                                    word.charAt(0).toUpperCase() +
                                        word.slice(1);
                                }

                                return word;
                            })
                            .join("");

                        //#region Conditionally Mandatory
                        if (modifiedOptionName === "conditionallyMandatory") {
                            // Create conditionally mandatory object
                        } else if (modifiedOptionName === "mandatedByField") {
                            const mandatedByFieldId = parseInt(
                                fieldOption["Value"],
                                10
                            );
                            if (!isNaN(mandatedByFieldId)) {
                                conditionallyMandatory.mandatedByFieldId =
                                    mandatedByFieldId;
                            }
                        } else if (modifiedOptionName === "mandatedByValue") {
                            conditionallyMandatory.mandatedByValue =
                                fieldOption["Value"];
                        } else if (
                            modifiedOptionName === "mandatedIfFieldHasValue"
                        ) {
                            conditionallyMandatory.mandatedIfFieldHasValue =
                                BooleanUtilityMethods.convertToBoolean(
                                    fieldOption["Value"]
                                );
                        }
                        //#endregion

                        //#region Searchable
                        else if (modifiedOptionName === "searchable") {
                            fieldTemplate.searchable =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (
                            modifiedOptionName === "requiresSearchBeforeAdd"
                        ) {
                            fieldTemplate.requiresSearchBeforeAdd =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (
                            modifiedOptionName === "applyOnQuickWorkspaceSearch"
                        ) {
                            fieldTemplate.applyOnQuickWorkspaceSearch =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (modifiedOptionName === "defaultSearchType") {
                            fieldTemplate.defaultSearchType = optionValue;
                        } else if (
                            modifiedOptionName === "defaultSearchValue"
                        ) {
                            fieldTemplate.defaultSearchValue = optionValue;
                        } else if (modifiedOptionName === "defaultSoundsLike") {
                            fieldTemplate.defaultSoundsLike =
                                SoundsLikeMode[
                                    optionValue as keyof typeof SoundsLikeMode
                                ];
                        }
                        //#endregion

                        //#region
                        else if (modifiedOptionName === "showAge") {
                            fieldTemplate.showAge =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (modifiedOptionName === "displayYears") {
                            fieldTemplate.displayYears =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (modifiedOptionName === "displayMonths") {
                            fieldTemplate.displayMonths =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (modifiedOptionName === "displayDays") {
                            fieldTemplate.displayDays =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        }
                        //#endregion
                        else if (modifiedOptionName === "isSimpleLongString") {
                            fieldTemplate[modifiedOptionName] =
                                BooleanUtilityMethods.convertToBoolean(
                                    optionValue
                                );
                        } else if (modifiedOptionName === "allowUpdates") {
                            fieldTemplate.allowUpdates =
                                BooleanUtilityMethods.toBooleanTrueIfEmpty(
                                    fieldOption["Value"]
                                );
                        } else {
                            fieldTemplate[modifiedOptionName] = optionValue;
                        }
                    });

                    delete fieldTemplate[fieldProp];
                    if (Object.keys(conditionallyMandatory).length > 0) {
                        fieldTemplate["conditionallyMandatory"] =
                            conditionallyMandatory;
                    }
                }
                //#endregion
                else if (camelCaseFieldProp === "label") {
                    fieldTemplate.label = fieldTemplate[fieldProp];
                } else if (
                    camelCaseFieldProp === "addType" &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate.type = transformFieldType(fieldTemplate);
                    delete fieldTemplate["addType"];
                } else if (
                    camelCaseFieldProp === "editType" &&
                    fieldTemplate[fieldProp]
                ) {
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "viewType") {
                    delete fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "sortDirection") {
                    if (fieldTemplate[fieldProp] == SortDirection.Ascending) {
                        fieldTemplate.sortDirection = SortDirection.Ascending;
                    } else {
                        fieldTemplate.sortDirection = SortDirection.Descending;
                    }
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null &&
                    fieldTemplate[fieldProp].match(
                        /{\[(?:(?<reference>[a-zA-Z0-9]*):){0,1}(?:(?<systemName>[a-zA-Z0-9]*)){1}\]}/g
                    ) != null
                ) {
                    graph
                        .getNodeByID(fieldTemplate.datalistID)
                        ?.mirrorFields.add(fieldTemplate.systemName);
                } else if (
                    camelCaseFieldProp === "defaultValue" &&
                    fieldTemplate[fieldProp] != null
                ) {
                    fieldTemplate.defaultValue = fieldTemplate[fieldProp];
                } else if (camelCaseFieldProp === "adminOnlyInd") {
                    fieldTemplate.adminOnly =
                        BooleanUtilityMethods.convertToBoolean(
                            fieldTemplate[fieldProp]
                        );
                    delete fieldTemplate[fieldProp];
                }
                // Rest of the properties
                if (
                    camelCaseFieldProp != fieldProp &&
                    fieldTemplate[fieldProp]
                ) {
                    fieldTemplate[camelCaseFieldProp] =
                        fieldTemplate[fieldProp];
                    delete fieldTemplate[fieldProp];
                }
            }
        }
        delete fieldTemplate["dependencies"];
        delete fieldTemplate["allDependenciesMustPass"];
        delete fieldTemplate["displayOption"];
        delete fieldTemplate["DisplayOption"];

        // Only add if there are dependencies defined on the field template
        if (fieldDependency?.dependencies?.length > 0) {
            fieldTemplate.visibilityDependency = fieldDependency;
        }

        // Update show
        updateShowInHeaderFieldTemplateDTO(fieldTemplate, graph);
        delete fieldTemplate["fieldId"];
        return fieldTemplate;
    };

    const updateShowInHeaderFieldTemplateDTO = (
        fieldTemplate: FieldTemplateDTO,
        graph: Graph<DatalistTemplateDTO>
    ) => {
        const templateNode = graph.getNodeByID(fieldTemplate.datalistID);
        if (templateNode == null) {
            return;
        }
        const optionTemplate = templateNode.data.optionTemplate;

        if (optionTemplate && optionTemplate.recordHeaderColumns.length > 0) {
            fieldTemplate.showInHeader =
                optionTemplate.recordHeaderColumns.some(
                    (fieldID) => fieldID === fieldTemplate.fieldID
                );
        } else {
            const labelTemplate = templateNode.data.labelTemplate;
            fieldTemplate.showInHeader = labelTemplate.fields?.some(
                (fieldID) => fieldID === fieldTemplate.fieldID
            );
        }
    };
    const transformItemsToFieldTemplateDTOOptions = (
        fieldTemplate: any
    ): any => {
        const fieldTemplateItems = fieldTemplate.items;
        switch (fieldTemplate.type) {
            // This is for TODO, DataMover, etc. Not supported yet
            case FieldTypes.Datalist:
                break;
            case FieldTypes.User:
                break;
            case FieldTypes.UserRoles:
                fieldTemplate.organizationRoles = new Map<number, string>();
                fieldTemplateItems.forEach((item: any) => {
                    fieldTemplate.organizationRoles.set(item.Key, item.Value);
                });
                break;
            case FieldTypes.DynamicDropdown:
                fieldTemplate.source = fieldTemplateItems[0].source;
                fieldTemplate.root = fieldTemplateItems[0].root;
                break;
            case FieldTypes.CascadingDropdown: {
                const cascadingDropdownValues: CascadingDropdownFieldValue[] =
                    [];
                fieldTemplateItems.forEach((item: any) => {
                    const dropdownValue = new CascadingDropdownFieldValue();
                    if (Object.prototype.hasOwnProperty.call(item, "parents")) {
                        dropdownValue.parent = item.Parent ?? []; // This is an array. Length check?
                    } else if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    } else if (
                        Object.prototype.hasOwnProperty.call(item, "value")
                    ) {
                        dropdownValue.value = item.Value;
                    } else if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item["displayValue"];
                    } else if (
                        Object.prototype.hasOwnProperty.call(item, "scores")
                    ) {
                        //For cascading dropdown fields scores are on the item Scores property
                        interceptorUtilityMethods.transformScores(
                            item.Scores,
                            dropdownValue.scores
                        );

                        dropdownValue.backgroundColor =
                            item.scores.BackgroundColor;
                        dropdownValue.color = item.scores.Color;
                        dropdownValue.disabled = item.scores.IsDisabled;
                    } else if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }
                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    cascadingDropdownValues.push(dropdownValue);
                });
                // TODO: Define concrete relationship between fieldTemplate and DropdownFieldValue
                fieldTemplate.options = cascadingDropdownValues;
                break;
            }
            case FieldTypes.Dropdown: {
                const dropdownValues: DropdownFieldValue[] = [];
                fieldTemplateItems.forEach((item: any) => {
                    const dropdownValue = new DropdownFieldValue();
                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "FieldValueID"
                        )
                    ) {
                        dropdownValue.fieldValueID = item.FieldValueID;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "value")) {
                        dropdownValue.value = item.value;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "displayValue"
                        )
                    ) {
                        dropdownValue.displayValue = item["displayValue"];
                    }

                    //For dropdown fields scores are directly on the item value
                    interceptorUtilityMethods.transformScores(
                        item,
                        dropdownValue.scores
                    );

                    if (
                        Object.prototype.hasOwnProperty.call(
                            item,
                            "BackgroundColor"
                        )
                    ) {
                        dropdownValue.backgroundColor = item.BackgroundColor;
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(item, "IsDisabled")
                    ) {
                        dropdownValue.disabled = item.IsDisabled;
                    }

                    if (Object.prototype.hasOwnProperty.call(item, "Color")) {
                        dropdownValue.color = item.Color;
                    }
                    dropdownValues.push(dropdownValue);
                });
                fieldTemplate.options = dropdownValues;
                break;
            }
            case FieldTypes.Boolean: {
                const dropdownValue: DropdownFieldValue =
                    new DropdownFieldValue();
                //Process scores for Boolean fields
                //For boolean fields scores are in the first element of the items array

                if (
                    Object.hasOwn(fieldTemplate, "items") &&
                    Array.isArray(fieldTemplate.items) &&
                    fieldTemplate.items.length > 0
                ) {
                    const fieldTemplateItem = fieldTemplate.items[0];

                    interceptorUtilityMethods.transformScores(
                        fieldTemplateItem,
                        dropdownValue.scores
                    );
                }
                fieldTemplate.scoreInformation = dropdownValue;
                break;
            }
            case FieldTypes.CascadingDynamicDropdown:
            case FieldTypes.EmbeddedList:
            case FieldTypes.EmbeddedDocument:
            case FieldTypes.Number:
            case FieldTypes.LongString:
            default:
                break;
        }
        // Regex possibly
    };

    const transformTemplates = (templates: any): any => {
        const graph = new Graph<DatalistTemplateDTO>([]);

        if (templates == null || templates.length === 0) {
            logger.info("No templates found");
            return graph;
        }

        if (typeof templates == "object" && !Array.isArray(templates)) {
            templates = [templates];
        }
        // Get template DTOs
        // const transformedTemplates = templates.map((template: any) => {
        //     return transformResponseToDatalistDTO(template, graph);
        // });

        const transformedTemplates: DatalistTemplateDTO[] = [];

        templates.forEach((template: any) => {
            try {
                const transformedTemplate = transformResponseToDatalistDTO(
                    template,
                    graph
                );
                transformedTemplates.push(transformedTemplate);
            } catch (error) {
                logger.error(
                    `Error transforming template ${template.datalistID}`
                );
            }
        });
        logger.debug(`Transformed ${transformedTemplates.length} templates`);
        // Add relationships
        transformedTemplates.forEach((template: DatalistTemplateDTO) => {
            // Relationships
            template.children.forEach(
                (child: DatalistRelationshipTemplateDTO) => {
                    if (
                        !graph.getNodeByID(template.datalistID) ||
                        !graph.getNodeByID(child.childListID)
                    ) {
                        return;
                    }
                    graph.addEdge(
                        graph.getNodeByID(template.datalistID),
                        graph.getNodeByID(child.childListID)
                    );
                    graph
                        .getNodeByID(child.childListID)
                        ?.addParentDependency(
                            graph.getNodeByID(template.datalistID)
                        );
                }
            );
        });

        logger.debug(`Templates transformed and relationships added`);
        graph.nodes.forEach((node: Node<DatalistTemplateDTO>) => {
            const mirrorFields = node.mirrorFields;
            mirrorFields.forEach((fieldSystemName: string) => {
                const mirrorField = node.fieldMap.get(fieldSystemName);

                if (mirrorField) {
                    try {
                        generateMirrorDependencyForFieldTemplate(
                            mirrorField,
                            graph
                        );
                    } catch (error) {
                        logger.error(
                            `Error generating mirror dependency for field ${fieldSystemName} on datalist ${node.data.datalistID}`
                        );
                    }
                }
            });

            // These are DDD fields
            const dynamicFields = node.dynamicFields;
            dynamicFields.forEach((fieldSystemName: string) => {
                const dynamicField = node.fieldMap.get(fieldSystemName);

                if (dynamicField) {
                    //TODO: This logic will need to be more refined because of root and source complexity
                    const dynamicFieldSource = (
                        dynamicField as DynamicDropdownFieldTemplateDTO
                    ).source;

                    if (dynamicFieldSource) {
                        const parent = graph.getNodeByID(dynamicFieldSource);
                        // DDD datalist will be a parent to the current datalist
                        if (parent) {
                            graph.addEdge(parent, node);
                        } else {
                            //TODO: Is this a critical error?
                            logger.error(
                                `Parent not found for DDD field ${dynamicField.systemName} with source ${dynamicFieldSource}`
                            );
                        }
                    }
                }
            });
        });
        logger.debug(`Graph created with ${graph.nodes.size} nodes`);
        return graph;
    };

    const transformDataToClientSideEventTemplate = (
        clientSideEventTemplate: any
    ): any => {
        const newClientSideEventTemplate = new ClientSideEventTemplateDTO();
        for (const prop in clientSideEventTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    clientSideEventTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    clientSideEventTemplate[camelCaseProp] =
                        clientSideEventTemplate[prop];
                    delete clientSideEventTemplate[prop];
                }

                if (camelCaseProp === "clientWorkFlowOptions") {
                    const clientWorkflowOptions =
                        new ClientWorkflowOptionsDTO();
                    for (const clientWorkflowOptionProp in clientSideEventTemplate[
                        camelCaseProp
                    ]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                clientSideEventTemplate[camelCaseProp],
                                clientWorkflowOptionProp
                            )
                        ) {
                            const camelCaseClientWorkflowOptionProp =
                                clientWorkflowOptionProp[0].toLowerCase() +
                                clientWorkflowOptionProp.slice(1);
                            if (
                                camelCaseClientWorkflowOptionProp == "triggers"
                            ) {
                                clientWorkflowOptions.triggers =
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ];
                            } else if (
                                camelCaseClientWorkflowOptionProp ==
                                "valueChangeFields"
                            ) {
                                clientWorkflowOptions.valueChangeFields =
                                    clientSideEventTemplate[camelCaseProp][
                                        clientWorkflowOptionProp
                                    ];
                            }
                        }
                    }

                    delete clientSideEventTemplate[camelCaseProp];
                    clientSideEventTemplate.clientWorkflowOptions =
                        clientWorkflowOptions;
                }
            }
        }

        Object.assign(newClientSideEventTemplate, clientSideEventTemplate);
        return newClientSideEventTemplate;
    };

    const transformDataToSearchableDescendantListsTemplate = (
        searchableDescendantListsTemplate: any
    ): SearchableDescendantListsTemplateDTO => {
        const newSearchableDescendantListsTemplate =
            new SearchableDescendantListsTemplateDTO();
        for (const prop in searchableDescendantListsTemplate) {
            if (
                Object.prototype.hasOwnProperty.call(
                    searchableDescendantListsTemplate,
                    prop
                )
            ) {
                const camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp != prop) {
                    searchableDescendantListsTemplate[camelCaseProp] =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                }

                if (camelCaseProp === "dataListID") {
                    newSearchableDescendantListsTemplate.datalistID =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "dataListLabel") {
                    newSearchableDescendantListsTemplate.datalistLabel =
                        searchableDescendantListsTemplate[prop];
                    delete searchableDescendantListsTemplate[prop];
                } else if (camelCaseProp === "template") {
                    // This might be quite expensive. Need to check if this is necessary
                    // newSearchableDescendantListsTemplate.template =
                    //     transformResponseToDatalistDTO(
                    //         searchableDescendantListsTemplate[prop]
                    //     );
                    // delete searchableDescendantListsTemplate[prop];
                }
            }
        }

        Object.assign(
            newSearchableDescendantListsTemplate,
            searchableDescendantListsTemplate
        );
        return newSearchableDescendantListsTemplate;
    };

    const iconCharacterMap = new Map([
        ["iconid", "id"],
        ["backgroundiconcolor", "backgroundColor"],
        ["iconcategory", "category"],
        ["iconcolor", "color"],
        ["iconname", "name"],
        ["upgraded", "upgraded"],
    ]);

    const transformToIconTemplateDTO = (
        optionTemplate: any
    ): IconTemplateDTO => {
        const iconCharacter = optionTemplate.IconCharacter;
        const newIconTemplate = new IconTemplateDTO();
        const parseIconCharacter = JSON.parse(iconCharacter);
        for (const prop in parseIconCharacter) {
            if (iconCharacterMap.has(prop.toLowerCase())) {
                const characterValue = iconCharacterMap.get(prop.toLowerCase());
                if (characterValue == "category") {
                    parseIconCharacter[characterValue] = decodeURIComponent(
                        parseIconCharacter[prop]
                    );
                } else if (characterValue) {
                    parseIconCharacter[characterValue] =
                        parseIconCharacter[prop];
                }
                delete parseIconCharacter[prop];
            }
        }
        Object.assign(newIconTemplate, parseIconCharacter);

        if (optionTemplate.image) {
            newIconTemplate.image = optionTemplate.image;
        }
        return newIconTemplate;
    };

    const transformDataToOptionTemplateDTO = (
        optionTemplate: any
    ): OptionTemplateDTO => {
        const newOptionTemplate = new OptionTemplateDTO();
        // Option Template
        for (const prop in optionTemplate) {
            if (Object.prototype.hasOwnProperty.call(optionTemplate, prop)) {
                let camelCaseProp = prop[0].toLowerCase() + prop.slice(1);
                if (camelCaseProp === "dataListType") {
                    camelCaseProp = "datalistType";
                }
                if (camelCaseProp != prop) {
                    optionTemplate[camelCaseProp] = optionTemplate[prop];
                    delete optionTemplate[prop];
                }
                if (
                    camelCaseProp === "combinedGridOptionTemplate" &&
                    optionTemplate[prop]
                ) {
                    // This might be quite expensive. Need to check if this is necessary
                    const combinedGridOptionTemplate =
                        new CombinedGridOptionTemplateDTO();

                    for (const combinedGridProp in optionTemplate[prop]) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                optionTemplate[prop],
                                combinedGridProp
                            )
                        ) {
                            const camelCaseCombinedGridProp =
                                combinedGridProp[0].toLowerCase() +
                                combinedGridProp.slice(1);
                            if (camelCaseCombinedGridProp != combinedGridProp) {
                                optionTemplate[prop][
                                    camelCaseCombinedGridProp
                                ] = optionTemplate[prop][combinedGridProp];
                                delete optionTemplate[prop][combinedGridProp];
                            }
                        }
                    }
                    //TODO: Check if combined grid templates are being created properly
                    Object.assign(
                        combinedGridOptionTemplate,
                        optionTemplate[prop]
                    );

                    optionTemplate[camelCaseProp] = combinedGridOptionTemplate;
                }

                if (camelCaseProp === "preventInlineEdit") {
                    optionTemplate[camelCaseProp] =
                        BooleanUtilityMethods.convertToBoolean(
                            optionTemplate[camelCaseProp]
                        );
                }
                if (camelCaseProp === "preventInlineAdd") {
                    optionTemplate[camelCaseProp] =
                        BooleanUtilityMethods.convertToBoolean(
                            optionTemplate[camelCaseProp]
                        );
                }
                if (camelCaseProp === "hideWhenRead-Only") {
                    optionTemplate["hideWhenReadOnly"] =
                        BooleanUtilityMethods.convertToBoolean(
                            optionTemplate[camelCaseProp]
                        );
                    delete optionTemplate[camelCaseProp];
                }

                if (camelCaseProp === "iconCharacter") {
                    // image => iconCharacter
                    optionTemplate[camelCaseProp] =
                        transformToIconTemplateDTO(optionTemplate);
                    delete optionTemplate[prop];
                }
                if (camelCaseProp === "topLevelDisplay") {
                    optionTemplate["isTopLevelDisplay"] =
                        BooleanUtilityMethods.convertToBoolean(
                            optionTemplate[camelCaseProp]
                        );
                    delete optionTemplate[camelCaseProp];
                }
            }
        }
        // Assign back to template object
        // TODO: Separate transformation for option template especially combined grid
        Object.assign(newOptionTemplate, optionTemplate);

        return newOptionTemplate;
    };
    //#endregion
    //#region Interceptor
    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            const start = performance.now();

            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !response.url.includes(approvedRoute)
            ) {
                return response;
            }

            // Process all templates
            response = response.clone({
                body: serverTemplateParseService.transformTemplates(
                    response.body
                ),
            });

            const end = performance.now();
            console.log(
                `Template Interceptor took ${end - start} milliseconds.`
            );
            return response;
        })
    );
    //#endregion
};
