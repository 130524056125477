import { inject, Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { RecordInstanceDTO } from "../models/recordInstance/recordInstance.dto";
import { FieldInstanceDTO } from "../models/fieldInstances/field-instance.dto";
import { IIndexable } from "../../../core/base/indexable.interface";
import { BaseRecordValidationError } from "../../core/base/baseErrors/base-mcase-error";
import { RecordError } from "../../core/domain/diagnostic/record-error.model";
import { RecordWarning } from "../../core/domain/diagnostic/record-warning.model";
import { RecordMessage } from "../../core/domain/diagnostic/record-message.model";
import { RecordErrorType } from "../../../core/domain/enums/record-error-type.enum";
import { RecordTransitionResponseDTO } from "../models/diagnostic/record-transition-response.dto";
import { ListDataDTO } from "../models/recordInstance/list-data.dto";
import { FieldTypes } from "../../../core/domain/enums/field-types.enum";
import { LargeObjectStore } from "../../infrastructure/stores/large-object.store";

@Injectable({
    providedIn: "root",
})
export class JsonRecordInstanceMapper extends Mapper<
    Promise<IIndexable>,
    RecordInstanceDTO
> {
    private largeObjectStore = inject(LargeObjectStore);
    /**
     * Maps a JSON Record Data from server to a `RecordInstanceDTO` object.
     *
     * @param jsonRecordInstance - The JSON object representing the record instance.
     * @returns A `RecordInstanceDTO` object populated with data from the JSON record instance.
     *
     * The function performs the following transformations:
     * - Copies all properties from `jsonRecordInstance` to a new `RecordInstanceDTO` object.
     * - Maps specific properties from `jsonRecordInstance` to corresponding properties in `RecordInstanceDTO`:
     *   - `Nonces` to `nonces`
     *   - `listId` to `datalistID`
     *   - `id` to `recordInstanceID`
     *   - `parentRecordID` to `parentRecordInstanceID`
     * - If the `fields` property exists in `jsonRecordInstance`, it maps each field in the array to a `FieldInstanceDTO` object and assigns the resulting array to `fieldInstanceDTOs` in `RecordInstanceDTO`.
     * - For each field in the `fields` array, the following properties are mapped:
     *   - `fieldInstanceId` to `fieldInstanceID`
     *   - `recordInstanceId` to `recordInstanceID`
     *   - `fieldId` to `fieldID`
     *   - `value` to `value`
     *   - `displayValue` to `displayValue`
     *   - `fieldSysName` to `fieldSystemName`
     *   - `dynamicRef` to `dynamicRef`
     *   - `Checksum` to `checksum`
     */
    mapFrom(jsonRecordInstance: IIndexable): RecordInstanceDTO {
        // Transform the record chunk into a RecordInstanceDTO object
        const recordInstanceDTO: RecordInstanceDTO = Object.assign(
            new RecordInstanceDTO(),
            jsonRecordInstance
        );

        // Map specific properties from json to the RecordInstanceDTO object
        if (Object.hasOwnProperty.call(jsonRecordInstance, "Nonces")) {
            recordInstanceDTO.nonces = jsonRecordInstance["Nonces"];
        }

        if (Object.hasOwnProperty.call(jsonRecordInstance, "listId")) {
            recordInstanceDTO.datalistID = jsonRecordInstance["listId"];
        }
        if (Object.hasOwnProperty.call(jsonRecordInstance, "id")) {
            recordInstanceDTO.recordInstanceID = jsonRecordInstance["id"];
        }
        if (Object.hasOwnProperty.call(jsonRecordInstance, "parentRecordID")) {
            recordInstanceDTO.parentRecordInstanceID =
                jsonRecordInstance["parentRecordID"];
        }
        if (Object.hasOwnProperty.call(jsonRecordInstance, "status")) {
            recordInstanceDTO.status = jsonRecordInstance["status"];
        }

        if (Object.hasOwnProperty.call(jsonRecordInstance, "diagnostics")) {
            recordInstanceDTO.recordTransitionResponseDTO =
                this.translateRecordTransitionResponse(jsonRecordInstance);
        }

        if (Object.hasOwnProperty.call(jsonRecordInstance, "fields")) {
            const fieldInstanceDTOs: FieldInstanceDTO[] = jsonRecordInstance[
                "fields"
            ].map((jsonFieldInstance: IIndexable) => {
                const fieldInstanceDTO = new FieldInstanceDTO();
                if (
                    Object.hasOwnProperty.call(
                        jsonFieldInstance,
                        "fieldInstanceId"
                    )
                ) {
                    fieldInstanceDTO.fieldInstanceID =
                        jsonFieldInstance["fieldInstanceId"];
                }
                if (
                    Object.hasOwnProperty.call(
                        jsonFieldInstance,
                        "recordInstanceId"
                    )
                ) {
                    fieldInstanceDTO.recordInstanceID =
                        jsonFieldInstance["recordInstanceId"];
                }
                if (Object.hasOwnProperty.call(jsonFieldInstance, "fieldId")) {
                    fieldInstanceDTO.fieldID = jsonFieldInstance["fieldId"];
                }
                if (Object.hasOwnProperty.call(jsonFieldInstance, "value")) {
                    fieldInstanceDTO.value = jsonFieldInstance["value"];
                }
                if (
                    Object.hasOwnProperty.call(
                        jsonFieldInstance,
                        "displayValue"
                    )
                ) {
                    fieldInstanceDTO.displayValue =
                        jsonFieldInstance["displayValue"];
                }
                if (
                    Object.hasOwnProperty.call(
                        jsonFieldInstance,
                        "fieldSysName"
                    )
                ) {
                    fieldInstanceDTO.fieldSystemName =
                        jsonFieldInstance["fieldSysName"];
                }
                if (
                    Object.hasOwnProperty.call(jsonFieldInstance, "dynamicRef")
                ) {
                    fieldInstanceDTO.dynamicRef =
                        jsonFieldInstance["dynamicRef"];
                }
                if (Object.hasOwnProperty.call(jsonFieldInstance, "Checksum")) {
                    fieldInstanceDTO.checksum = jsonFieldInstance["Checksum"];
                }

                if (Object.hasOwnProperty.call(jsonFieldInstance, "type")) {
                    // TODO: Use FieldTypes conversion
                    fieldInstanceDTO.type = jsonFieldInstance["type"];
                }
                return fieldInstanceDTO;
            });
            recordInstanceDTO.fieldInstanceDTOs = fieldInstanceDTOs;
        }

        if (Object.hasOwnProperty.call(jsonRecordInstance, "lists")) {
            recordInstanceDTO.lists = jsonRecordInstance["lists"].map(
                (listData: any) => {
                    const listDataDTO = new ListDataDTO();
                    listDataDTO.datalistID = listData.listId;
                    if (!listDataDTO.records) {
                        return listDataDTO;
                    }

                    listDataDTO.records = listData.records.map(
                        (recordInstanceDTO: RecordInstanceDTO) => {
                            return this.mapFrom(recordInstanceDTO);
                        }
                    );

                    return listDataDTO;
                }
            );
        }
        return recordInstanceDTO;
    }

    /**
     * Maps a RecordInstanceDTO object to an IIndexable JSON object.
     * From RecordInstanceDTO to RecordData server
     *
     * @param param - The RecordInstanceDTO object to be mapped.
     * @returns A Promise that resolves to an IIndexable JSON object.
     *
     * @throws {Error} If the provided parameter is invalid.
     *
     * The resulting JSON object contains the following properties:
     * - Nonces: The nonces from the RecordInstanceDTO.
     * - listId: The datalistID from the RecordInstanceDTO.
     * - id: The recordInstanceID from the RecordInstanceDTO.
     * - parentRecordID: The parentRecordInstanceID from the RecordInstanceDTO.
     * - checksum: The checksum from the RecordInstanceDTO.
     * - status: The status from the RecordInstanceDTO.
     * - createdOn: The createdOn date from the RecordInstanceDTO.
     * - isNew: The isNew flag from the RecordInstanceDTO.
     * - isChanged: The isChanged flag from the RecordInstanceDTO.
     * - isDeleted: The isDeleted flag from the RecordInstanceDTO.
     * - lists: The serialized embedded lists from the RecordInstanceDTO.
     * - fields: An array of field objects, each containing:
     *   - fieldInstanceId: The fieldInstanceID from the FieldInstanceDTO.
     *   - recordInstanceId: The recordInstanceID from the FieldInstanceDTO.
     *   - fieldId: The fieldID from the FieldInstanceDTO.
     *   - value: The value from the FieldInstanceDTO, potentially converted for large objects.
     *   - displayValue: The displayValue from the FieldInstanceDTO.
     *   - fieldSysName: The fieldSystemName from the FieldInstanceDTO.
     *   - dynamicRef: The dynamicRef from the FieldInstanceDTO.
     *   - Checksum: The checksum from the FieldInstanceDTO.
     *   - type: The type of the field, specifically for attachments.
     */
    override async mapTo(param: RecordInstanceDTO): Promise<IIndexable> {
        if (!param) {
            throw new Error(
                `JsonRecordInstanceMapper: mapTo - Invalid parameter`
            );
        }
        const recordJsonData: IIndexable = new Object();

        recordJsonData["Nonces"] = param.nonces;
        recordJsonData["listId"] = param.datalistID;
        recordJsonData["id"] = param.recordInstanceID;
        recordJsonData["parentRecordID"] = param.parentRecordInstanceID;
        recordJsonData["checksum"] = param.checksum;
        recordJsonData["status"] = param.status;
        recordJsonData["createdOn"] = param.createdOn;
        recordJsonData["isNew"] = param.isNew;
        recordJsonData["ownerUserID"] = param.recordOwnerID;
        recordJsonData["isChanged"] = param.isChanged;
        recordJsonData["isDeleted"] = param.isDeleted;
        recordJsonData["lists"] = await this.serializeEmbeddedLists(
            param.lists
        );
        recordJsonData["fields"] = await Promise.all(
            param.fieldInstanceDTOs.map(
                async (fieldInstanceDTO: FieldInstanceDTO) => {
                    if (
                        fieldInstanceDTO.type !== FieldTypes.Attachment &&
                        fieldInstanceDTO.type !== FieldTypes.EmbeddedDocument
                    ) {
                        return {
                            fieldInstanceId: fieldInstanceDTO.fieldInstanceID,
                            recordInstanceId: fieldInstanceDTO.recordInstanceID,
                            fieldId: fieldInstanceDTO.fieldID,
                            value: fieldInstanceDTO.value,
                            displayValue: fieldInstanceDTO.displayValue,
                            fieldSysName: fieldInstanceDTO.fieldSystemName,
                            dynamicRef: fieldInstanceDTO.dynamicRef,
                            Checksum: fieldInstanceDTO.checksum,
                        };
                    }
                    const value =
                        await this.largeObjectValueConverter(fieldInstanceDTO);
                    return {
                        fieldInstanceId: fieldInstanceDTO.fieldInstanceID,
                        recordInstanceId: fieldInstanceDTO.recordInstanceID,
                        fieldId: fieldInstanceDTO.fieldID,
                        value: value,
                        type: fieldInstanceDTO.type,
                        displayValue: "",
                        fieldSysName: fieldInstanceDTO.fieldSystemName,
                        dynamicRef: fieldInstanceDTO.dynamicRef,
                        Checksum: fieldInstanceDTO.checksum,
                    };
                }
            )
        );

        return recordJsonData;
    }

    /**
     * Serializes embedded lists by mapping each list's records to a new format.
     *
     * @param lists - An array of ListDataDTO objects to be serialized.
     * @returns A promise that resolves to an array of IIndexable objects, each containing a listId and its corresponding records.
     *
     * @remarks
     * - If the input `lists` array is null or empty, the function returns an empty array.
     * - Each record in the list is mapped using the `mapTo` method.
     */
    private async serializeEmbeddedLists(
        lists: ListDataDTO[]
    ): Promise<IIndexable[]> {
        if (!lists || lists.length === 0) {
            return [];
        }
        return await Promise.all(
            lists.map(async (list: ListDataDTO) => {
                const records = await Promise.all(
                    list.records.map(
                        async (recordInstanceDTO: RecordInstanceDTO) => {
                            // Update status to changed if record is not deleted
                            // and is not new
                            if (
                                recordInstanceDTO.recordInstanceID >= 0 &&
                                !recordInstanceDTO.isNew &&
                                !recordInstanceDTO.isDeleted
                            ) {
                                recordInstanceDTO.isChanged = true;
                            }
                            return await this.mapTo(recordInstanceDTO);
                        }
                    )
                );
                const listObj = {
                    listId: list.datalistID,
                    records: records,
                };
                return listObj;
            })
        );
    }

    /**
     * Converts a large object value to a Base64 string.
     *
     * @param fieldInstanceID - The ID of the field instance to retrieve the large object value.
     * @returns A promise that resolves to a Base64 string representation of the large object value.
     */
    private largeObjectValueConverter = async (
        fieldInstanceDTO: FieldInstanceDTO
    ): Promise<string> => {
        const largeObject = this.largeObjectStore.getById(
            fieldInstanceDTO.fieldInstanceID
        );
        if (!largeObject || !largeObject.value) {
            return Promise.resolve("");
        }
        if (fieldInstanceDTO.type === FieldTypes.EmbeddedDocument) {
            return await this.file2Json(largeObject.value);
        } else {
            return await this.file2Base64(largeObject.value);
        }
    };

    /**
     * Converts a given file to a JSON string.
     *
     * @param file - The file to be converted.
     * @returns A promise that resolves to a string in the format `Data:<fileName>;<fileContent>`.
     */
    private file2Json = async (file: File): Promise<string> => {
        const json = await file.text();
        const pdfNmae = file.name;
        return `Data:${pdfNmae};${json}`;
    };

    /**
     * Converts a given File object to a Base64 encoded string.
     *
     * @param file - The File object to be converted.
     * @returns A Promise that resolves to a Base64 encoded string representation of the file.
     *
     */
    private file2Base64 = (file: File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result?.toString() || "");
            reader.onerror = (error) => reject(error);
        });
    };
    /**
     * Translates the JSON record instance data into a `RecordTransitionResponseDTO`.
     *
     * @param jsonRecordInstanceData - The JSON data representing the record instance.
     * @returns A `RecordTransitionResponseDTO` object populated with the translated data.
     *
     * The function performs the following transformations:
     * - Maps the `diagnostics` property from the JSON data to the DTO, handling case sensitivity.
     * - Extracts and maps `Message`, `Error`, and `Warning` arrays from `diagnostics` to the DTO's `validationResponses` property.
     * - Sets the `status`, `modifiedRecordIDs`, `failedEventName`, `failedEventTrigger`, and `redirectedRecordID` properties of the DTO.
     */
    private translateRecordTransitionResponse(
        jsonRecordInstanceData: IIndexable
    ): RecordTransitionResponseDTO {
        const recordTransitionResponseDTO = new RecordTransitionResponseDTO();
        if (Object.hasOwnProperty.call(jsonRecordInstanceData, "diagnostics")) {
            // Map jsonRecordInstanceData to recordTransitionResponseDTO, handling case sensitivity
            const diagnosticsData = jsonRecordInstanceData["diagnostics"];
            const recordErrors: BaseRecordValidationError[] = [];
            // Map the capitalized arrays to the lowercase DTO properties
            const messages = diagnosticsData.Message || [];
            const errors = diagnosticsData.Error || [];
            const warnings = diagnosticsData.Warning || [];
            recordTransitionResponseDTO.status = diagnosticsData.status || 0;

            // Add messages to recordErrors
            if (messages.length > 0) {
                recordErrors.push(
                    ...messages.map(
                        (message: string) => new RecordMessage(message)
                    )
                );
            }

            // Add errors to recordErrors
            if (errors.length > 0) {
                recordErrors.push(
                    ...errors.map(
                        (message: string) =>
                            new RecordError(message, RecordErrorType.Mapper)
                    )
                );
            }

            // Add warnings to recordErrors
            if (warnings.length > 0) {
                recordErrors.push(
                    ...warnings.map(
                        (message: string) => new RecordWarning(message)
                    )
                );
            }
            recordTransitionResponseDTO.modifiedRecordIDs =
                diagnosticsData.modifiedRecordIDs || [];
            recordTransitionResponseDTO.failedEventName =
                diagnosticsData.failedEventName || "";
            recordTransitionResponseDTO.failedEventTrigger =
                diagnosticsData.failedEventTrigger || 0;
            recordTransitionResponseDTO.redirectedRecordID =
                diagnosticsData.redirectedRecordID || null;
            recordTransitionResponseDTO.validationResponses = recordErrors;
        }

        return recordTransitionResponseDTO;
    }
}
