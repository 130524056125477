/**
 * Interceptor for handling chunked record data in HTTP requests.
 * This interceptor transforms the record chunks in the response body into RecordInstanceDTO objects.
 * It also logs the transformation time for each record chunk.
 */
import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { ChunkDTO } from "../../data/model/chunker/chunk.dto";

/**
 * The chunkInterceptor function is the main interceptor logic for handling chunked record data.
 * It transforms the record chunks in the response body into RecordInstanceDTO objects.
 * @param request The HTTP request being intercepted.
 * @param next The next HTTP handler in the interceptor chain.
 * @returns An Observable of the HTTP event.
 */
export const getTopLevelRecordsInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    // Inject the logger service
    const logger = inject(LOGGER);

    // Define the mCase routes from the environment
    const mCaseRoutes = environment.routes.mCase.routes;

    // Define the approved routes for chunked record data
    const approvedRoutes = [mCaseRoutes.getTopLevelRecords.url];

    const transformRequestBody = (requestBody: any): ChunkDTO => {
        const chunk: ChunkDTO = {
            records: requestBody.records,
            status: requestBody.status,
            lastSyncedActivityId: requestBody.LastSyncedActivityID,
            chunkID: requestBody.chunkID,
        };
        return chunk;
    };
    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !approvedRoutes.includes(response.url)
            ) {
                return response;
            }

            const start = performance.now();

            // Process all templates
            if (approvedRoutes.includes(response.url)) {
                response = response.clone({
                    body: transformRequestBody(response.body),
                });
            }

            const end = performance.now();
            logger.debug(
                `Get top level records interceptor took ${end - start}ms`
            );
            return response;
        })
    );
};
