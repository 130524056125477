/**
 * This file contains the implementation of the `largeObjectInterceptor` HTTP interceptor.
 * The interceptor is used to transform large objects in the HTTP response body.
 * It intercepts the response and applies the transformation logic to the large objects.
 * The transformed objects are then returned in the response body.
 */
import {
    HttpInterceptorFn,
    HttpRequest,
    HttpHandlerFn,
    HttpEvent,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { inject } from "@angular/core";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { LargeObjectDTO } from "../../data/model/largeObject/large-object.dto";
import { FileUtility } from "../../../../shared/utilities/type-extensions/file-utility-methods";
import { AttachmentType } from "../../../../record/data/models/options/attachment-type.enum";

/**
 * The `largeObjectInterceptor` function is the implementation of the HTTP interceptor.
 * It intercepts the HTTP request and response, and applies the transformation logic to large objects in the response body.
 * The transformed objects are then returned in the response body.
 *
 * @param request - The HTTP request being intercepted.
 * @param next - The next HTTP handler in the interceptor chain.
 * @returns An Observable of the HTTP event.
 */
export const largeObjectInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    // Define the mCase routes and the approved routes for transformation
    const mCaseRoutes = environment.routes.mCase.routes;
    const approvedRoutes = [
        mCaseRoutes.getLargeObjectData.url,
        mCaseRoutes.getLargeObject.url,
    ];
    const logger = inject(LOGGER);
    const fileUtility = inject(FileUtility);

    /**
     * Transforms an array of large objects into an array of LargeObjectDTO.
     *
     * @param largeObjects - The array of large objects to transform.
     * @returns The transformed array of LargeObjectDTO.
     */
    const transformLargeObjects = (largeObjects: any): LargeObjectDTO[] => {
        const start = performance.now();
        if (
            largeObjects == null ||
            largeObjects == "" ||
            largeObjects == undefined
        ) {
            return largeObjects;
        }

        const transformedLargeObject: LargeObjectDTO[] = largeObjects.map(
            (largeObject: any) => {
                // Transform large object here
                return transformLargeObject(largeObject);
            }
        );
        const end = performance.now();
        logger.debug(
            `Transformed large objects in ${end - start} milliseconds. Total large objects transformed: ${transformedLargeObject.length}`
        );
        return transformedLargeObject;
    };

    /**
     * Transforms a single large object into a LargeObjectDTO.
     *
     * @param largeObject - The large object to transform.
     * @returns The transformed LargeObjectDTO.
     */
    const transformLargeObject = (largeObject: any): LargeObjectDTO => {
        const start = performance.now();
        if (
            largeObject == null ||
            largeObject == "" ||
            largeObject == undefined
        ) {
            return largeObject;
        }
        // Transform large object here
        const largeObjectDTO = new LargeObjectDTO();

        largeObjectDTO.checksum = largeObject["Checksum"];
        largeObjectDTO.recordInstanceID = largeObject["RecordInstanceID"];
        largeObjectDTO.fieldInstanceID = largeObject["fieldId"];
        largeObjectDTO.displayValue = largeObject["displayValue"];
        largeObjectDTO.fieldID = largeObject["fieldId"];
        largeObjectDTO.type = largeObject["type"];
        largeObjectDTO.value = largeObject["value"];

        if (largeObject.value === "BigData") {
            largeObjectDTO.isBigData = true;
        }
        try {
            largeObjectDTO.value = fileUtility.base64ToFile(largeObject.value);
        } catch (error) {
            logger.error(
                `Error transforming large object with fieldInstanceID ${largeObjectDTO.fieldInstanceID} : ${error}`
            );
        }
        largeObjectDTO.type = getAttachmentType(largeObjectDTO);

        const end = performance.now();
        logger.debug(
            `Transformed large object with fieldInstanceID ${largeObjectDTO.fieldInstanceID} in ${end - start} milliseconds`
        );
        return largeObjectDTO;
    };

    const getAttachmentType = (largeObject: LargeObjectDTO): AttachmentType => {
        const mimeType = largeObject?.value?.type;

        if (largeObject.isBigData || !mimeType) {
            return AttachmentType.Other;
        }

        switch (true) {
            case validImageFormats.includes(mimeType):
                return AttachmentType.Image;
            case validAudioVideoFormats.includes(mimeType):
                return AttachmentType.Audio;
            case validDocumentFormats.includes(mimeType):
                return AttachmentType.PDF;
            case mimeType === "application/json":
                return AttachmentType.JSON;
            default:
                return AttachmentType.Other;
        }
    };

    // TODO: move to config file
    const validImageFormats: string[] = [
        "image/pjpeg",
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/x-windows-bmp",
        "image/bmp",
        "application/pdf",
    ];

    // TODO: move to config file
    const validAudioVideoFormats: string[] = [
        "video/x-mpeg",
        "audio/aac",
        "video/mp4",
        "video/x-msvideo",
        "video/mpeg",
        "video/quicktime",
    ];

    // TODO: move to config file
    const validDocumentFormats: string[] = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/x-msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/x-mspowerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    return next(request).pipe(
        map((response: HttpEvent<any>) => {
            // TODO: Implement context token for caching.
            // Do not process if not an HttpResponse or if the url is not in the approved routes
            if (
                !(response instanceof HttpResponse) ||
                response.url == "" ||
                response.url == null ||
                !approvedRoutes.some((url) => request.url.includes(url))
            ) {
                return response;
            }

            const start = performance.now();
            // Process all large objects during chunking
            if (mCaseRoutes.getLargeObjectData.url === response.url) {
                response = response.clone({
                    body: transformLargeObjects(response.body),
                });
            }

            // Process single large object via fieldInstanceID
            if (response.url?.startsWith(mCaseRoutes.getLargeObject.url)) {
                response = response.clone({
                    body: transformLargeObject(response.body),
                });
            }

            const end = performance.now();
            logger.debug(
                `Large Object Interceptor took ${end - start} milliseconds`
            );
            return response;
        })
    );
};
