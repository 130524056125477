import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../../../environments/environment";

export const subscriptionSearchResultsInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    // Return if does not match our interceptor rule
    if (
        !(request instanceof HttpRequest) ||
        !request.url ||
        !request.url.startsWith(
            environment.routes.mCase.routes.subscriptionSearch.url
        )
    ) {
        return next(request);
    }

    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            // Return if does not match our interceptor rule
            if (
                !(response instanceof HttpResponse) ||
                !response.url ||
                !request.url.startsWith(
                    environment.routes.mCase.routes.subscriptionSearch.url
                )
            ) {
                return response;
            }

            if (response instanceof HttpResponse) {
                response = response.clone({
                    body: transformResponseToCamelCase(
                        response.body as Record<string, unknown>
                    ),
                });
            }

            return response;
        })
    );
};

/**
 *  Transforming response from pascal case to camel case
 * @param object
 * @returns
 */
function transformResponseToCamelCase(
    object: Record<string, unknown>
): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const key in object) {
        const camelCaseKey = pascalToCamelCaseString(key);

        if (key === "diagnostics") {
            result[camelCaseKey] = pascalToCamelCaseObject(
                object[key] as Record<string, unknown>
            );
        } else if (key === "results") {
            result[camelCaseKey] = (object[key] as unknown[]).map((x) =>
                pascalToCamelCaseObject(x as Record<string, unknown>)
            );
        } else {
            result[camelCaseKey] = object[key];
        }
    }

    return result;
}

/**
 *  Transforming object from pascal case to camel case
 * @param object
 * @returns
 */
function pascalToCamelCaseObject(
    object: Record<string, unknown>
): Record<string, unknown> {
    if (!object) {
        return object;
    }

    const result: Record<string, unknown> = {};

    for (const key in object) {
        const newKey = pascalToCamelCaseString(key);
        result[newKey] = object[key];
    }

    return result;
}

/**
 * Transforming string value in pascal case to camel case
 * @param value
 * @returns
 */
function pascalToCamelCaseString(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
}
