import {
    ActivatedRouteSnapshot,
    BaseRouteReuseStrategy,
} from "@angular/router";

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
    // all other methods will be the same as the default

    /*
     * Determines if a route should be reused.
     * This strategy returns `true` when the future route config and current route config
     * and the future url and current url are identical.
     *
     * */
    override shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot
    ): boolean {
        return (
            future.routeConfig === curr.routeConfig &&
            future.url.toString() === curr.url.toString()
        );
    }
}
